import React from 'react';
import { Link } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import {
  Element,
  animateScroll as scroll,
  scroller,
} from 'react-scroll';
import PhoneInput from 'react-phone-number-input'
import validator from 'validator';
import axios from 'axios';

import $script from 'scriptjs';

import NavBar from 'components/NavBar';

import Facebook from './images/facebook.png';
import Google from './images/google.png';
import Yelp from './images/yelp.png';
import GoogleMaps from '../../components/GoogleMaps';

import './ContactPage.scss';

export default class ContactPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstname: '',
      lastname: '',
      address: '',
      phone: '',
      email: '',
      notes: '',
      error: null,
      submitButtonStatus: 1, // 1 - disabled, 2 - active, 3 - loading
      captchaCode: null,
      offer1: 1,
      offer2: 1,
      offer3: 1,
      offer1email: '',
      offer2email: '',
      offer3email: '',
      offer1error: null,
      offer2error: null,
      offer3error: null,
    };
    this.scrollListener = null;

    this.handleScroll = this.handleScroll.bind(this);

    this.scrollToTopOfPage = this.scrollToTopOfPage.bind(this);

    this.scrollToFreeInspection = this.scrollToFreeInspection.bind(this);
    this.scrollToLocations = this.scrollToLocations.bind(this);
    this.scrollToOffers = this.scrollToOffers.bind(this);
    this.scrollToWrite = this.scrollToWrite.bind(this);
    this.scrollToLicense = this.scrollToLicense.bind(this);

    this.submitContactInfo = this.submitContactInfo.bind(this);
    this.formIsComplete = this.formIsComplete.bind(this);
    this.captchaClicked = this.captchaClicked.bind(this);
    this.captchaExpired = this.captchaExpired.bind(this);

    this.sendRequestToServer = this.sendRequestToServer.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.sendOffer = this.sendOffer.bind(this);

    this.gtag_report_conversion = this.gtag_report_conversion.bind(this);
  }

  handleScroll() {
    this.setState({ scroll: window.scrollY });
  }

  gtag_report_conversion(url) {
    var callback = function () {
      if (typeof(url) != 'undefined') {
        window.location = url;
      }
    };
    window.gtag('event', 'conversion', {
        'send_to': 'AW-659389064/sCwZCMPM8tABEIj1tboC',
        'event_callback': callback
    });
    return false;
  }

  componentDidMount() {
    const el = document.querySelector('.contact-page__body-left');
    this.setState({
      top: el.offsetTop - 70,
      height: el.offsetHeight,
    });

    window.addEventListener('scroll', this.handleScroll, false);

    const path = window.location.hash;

    switch (path) {
      case '#Locations':
        this.scrollToLocations();
        break;
      case '#SpecialOffers':
        this.scrollToOffers();
        break;
      case '#WriteAReview':
        this.scrollToWrite();
        break;
      case '#GetFreeInspection':
        this.scrollToFreeInspection();
        break;
      case '#BusinessLicense':
        this.scrollToLicense();
        break;
      default:
        this.scrollToTopOfPage();
        break;
    }

    $script('https://www.google.com/recaptcha/api.js', () => {
      window.grecaptcha.ready(() => {
        window.grecaptcha.render(
          document.getElementById('my-recaptcha-placeholder'), {
            callback: this.captchaClicked, 
            sitekey: '6LdRlZYUAAAAALfPoft3BVhPHbNruTeaZn1WQwAu',
            'expired-callback': this.captchaExpired
        });
      });
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, false);
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.hash !== prevProps.hash) {
      if(this.props.hash && typeof(this.props.hash) == "string") {
        console.log('su')
        let hash = this.props.hash.split('#')[1];
        switch(hash) {
          case 'Locations':
            this.scrollToLocations();
            break;
          case 'SpecialOffers':
            this.scrollToOffers();
            break;
          case 'WriteAReview':
            this.scrollToWrite();
            break;
          case 'GetFreeInspection':
            this.scrollToFreeInspection();
            break;
          default:
            this.scrollToTopOfPage();
            break;
        }
      }
    }
  }

  async captchaClicked(code) {
    this.setState({
        submitButtonStatus: 2, 
        captchaCode: code
      });
    return true;
  }

  async captchaExpired() {
    this.setState({
      submitButtonStatus: 1, 
      captchaCode: null,
    });
    return true;
  }

  scrollToTopOfPage() {
    scroll.scrollToTop({duration: 0});
    this.setState({
      freeInspection: true,
      locations: false,
      offers: false,
      write: false,
      license: false
    });
  }

  scrollToFreeInspection() {
    window.location.hash = '#GetFreeInspection';
    scroller.scrollTo('freeInspection', {
      duration: 500,
      smooth: false,
      offset: -80,
    });

    this.setState({
      freeInspection: true,
      locations: false,
      offers: false,
      write: false,
      license: false
    });
  }

  scrollToLocations() {
    window.location.hash = '#Locations';
    scroller.scrollTo('locations', {
      duration: 500,
      smooth: false,
      offset: -80,
    });

    this.setState({
      freeInspection: false,
      locations: true,
      offers: false,
      write: false,
      license: false
    });
  }

  scrollToOffers() {
    window.location.hash = '#SpecialOffers';
    scroller.scrollTo('offers', {
      duration: 500,
      smooth: false,
      offset: -80,
    });

    this.setState({
      freeInspection: false,
      locations: false,
      offers: true,
      write: false,
      license: false
    });
  }

  scrollToWrite() {
    window.location.hash = '#WriteAReview';
    scroller.scrollTo('write', {
      duration: 500,
      smooth: false,
      offset: -80,
    });

    this.setState({
      freeInspection: false,
      locations: false,
      offers: false,
      write: true,
      license: false
    });
  }

  scrollToLicense() {
    window.location.hash = '#BusinessLicense';
    scroller.scrollTo('license', {
      duration: 500,
      smooth: false,
      offset: -80,
    });

    this.setState({
      freeInspection: false,
      locations: false,
      offers: false,
      write: false,
      license: true
    });
  }

  submitContactInfo() {
    // console.log(grecaptcha.reset())
    if (!this.formIsComplete()) {
        return false;
    } else {
        this.setState({
          submitButtonStatus: 3,
          error: null,
          success: null,
        }, () => {
          this.sendRequestToServer();
          this.gtag_report_conversion();
        });
    }
  }

  sendRequestToServer() {
    const { firstname, lastname, address, phone, email, captchaCode, notes} = this.state;

    axios.post('/api/contact', {
      firstname,
      lastname, 
      address, 
      phone,
      email, 
      notes,
      captchaCode
    })
    .then((response) => {
        window.grecaptcha.reset();
        this.setState({
          error: null,
          success: 'Thank you! You should an receive an email shortly. Please make sure to check your spam folder if you do not see it after a few minutes.',
          submitButtonStatus: 1, 
          captchaCode: null,
          firstname: '',
          lastname: '',
          address: '',
          phone: '',
          email: '',
          notes: '',
        });
    })
    .catch((error) => {
        window.grecaptcha.reset();

        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          this.setState({
            error: error.response.data, 
            submitButtonStatus: 1, 
            success: null,
            captchaCode: null,
          });

        } else {
          // Something happened in setting up the request that triggered an Error
          this.setState({
            error: 'Could not submit form. Try again.',
            submitButtonStatus: 1,
            success: null,
            captchaCode: null,
          });
        }
    });
  }

  formIsComplete() {
    const { firstname, lastname, address, phone, email } = this.state;
    let error = null;

    if (firstname === '' || !firstname) {
      error = 'Please enter your first name.';
    } else if (lastname === '' || !lastname) {
      error = 'Please enter your last name.';
    } else if (address === '' || !address) {
      error = 'Please enter your address.';
    } else if (!validator.isMobilePhone(phone) || phone === '' || !phone) {
      error = 'Please enter your phone number.';
    } else if (!validator.isEmail(email) || email === '' || !email) {
      error = 'Please enter a real email address';
    }

    if (error) {
      this.setState({ error });
      return false;
    } 

    return true;
  }

  handleKeyPress(event, number) {
    if(event.key === 'Enter') {
        this.sendOffer(number);
    }
  }

  sendOffer(number) {
    let email;
    switch(number) {
      case 1: 
        email = this.state.offer1email;
        break;
      case 2: 
        email = this.state.offer2email;
        break;
      case 3: 
        email = this.state.offer3email;
        break;
      default: 
        break;
    }

    if (!validator.isEmail(email)) {
        switch(number) {
          case 1: 
            this.setState({
              offer1error: true
            });
            break;
          case 2: 
            this.setState({
              offer2error: true
            });
            break;
          case 3: 
            this.setState({
              offer3error: true
            });
            break;
          default: 
            break;
        }
    } else {
          axios.post('/api/offers', {
            offer: String(number), 
            email: email,
          })
          .then((response) => {
              switch(number) {
                case 1: 
                  this.setState({
                    offer1: 3,
                    offer1email: '',
                    offer1error: null,
                  });
                  break;
                case 2: 
                  this.setState({
                    offer2: 3,
                    offer2email: '',
                    offer2error: null,
                  });
                  break;
                case 3: 
                  this.setState({
                    offer3: 3,
                    offer3email: '',
                    offer3error: null,
                  });
                  break;
                default: 
                  break;
              }
          })
          .catch((error) => {
            console.log(error);
          });
      }
  }

  render() {
    const { scroll, top } = this.state;

    let menuClass = 'contact-page__body-menu';
    menuClass += scroll > top ? ' contact-page__body-menu-fixed' : '';

    let freeInspectionClass = 'contact-page__body-menu-link';
    freeInspectionClass += this.state.freeInspection
      ? ' contact-page__body-menu-link-active'
      : '';

    let locationsClass = 'contact-page__body-menu-link';
    locationsClass += this.state.locations
      ? ' contact-page__body-menu-link-active'
      : '';

    let offersClass = 'contact-page__body-menu-link';
    offersClass += this.state.offers
      ? ' contact-page__body-menu-link-active'
      : '';

    let writeClass = 'contact-page__body-menu-link';
    writeClass += this.state.write
      ? ' contact-page__body-menu-link-active'
      : '';

    let licenseClass = 'contact-page__body-menu-link';
    licenseClass += this.state.license
      ? ' contact-page__body-menu-link-active'
      : '';



    return (
      <div className="contact-page">
        <NavBar nav={['Home', 'Contact Us']} color="yellow" />

        <div className="contact-page__body">
          <div className="contact-page__body-left">
            <div className={menuClass}>
              <div
                className={freeInspectionClass}
                onClick={this.scrollToFreeInspection}
              >
                Get Free Inspection
              </div>
              <div className={locationsClass} onClick={this.scrollToLocations}>
                Locations
              </div>
              <div className={offersClass} onClick={this.scrollToOffers}>
                Special Offers
              </div>
              <div className={writeClass} onClick={this.scrollToWrite}>
                Write a Review
              </div>
              <div className={licenseClass} onClick={this.scrollToLicense}>
                Business License
              </div>
              <div
                className="contact-page__body-menu-backtop"
                onClick={this.scrollToTopOfPage}
              >
                &#8593; Back to top
              </div>
            </div>
          </div>

          <div className="contact-page__body-right">
            <Element name="freeInspection">
              <div className="contact-page__body-freeinspection">
                <h1>Get Free Inspection</h1>
                <div className="contact-page__body-freeinspection-content">
                  We appreciate you taking an interest in{' '}
                  <strong>Emerald Pest Control</strong>! We live to serve all
                  your pest control needs and will make sure you, our customer,
                  are fully satisfied 360 days a year. Please take a moment to
                  fill out this form and we will follow up with you shortly. If
                  you want to reach us right away, please call
                  <strong>
                    <a href="tel:+14356191633"> 435-619-1633</a>
                  </strong>{' '}
                  to talk to one of our representatives.
                </div>

                <div className="contact-page__body-freeinspection-form">
                  <div className="row contact-page__body-freeinspection-form-row">
                    <div className="col-xs-12 col-sm-6 contact-page__body-freeinspection-form-col">
                      <input
                        type="text"
                        id="form-first-name"
                        placeholder="First Name"
                        value={ this.state.firstname }
                        onChange={ firstname => this.setState({ firstname: firstname.target.value }) }
                      />
                    </div>
                    <div className="col-xs-12 col-sm-6 contact-page__body-freeinspection-form-col">
                      <input
                        type="text"
                        id="form-last-name"
                        placeholder="Last Name"
                        value={ this.state.lastname }
                        onChange={ lastname => this.setState({ lastname: lastname.target.value }) }
                      />
                    </div>
                  </div>

                  <div className="row contact-page__body-freeinspection-form-row">
                    <div className="col-xs-12 col-sm-4 contact-page__body-freeinspection-form-col">
                      <input 
                        type="text" 
                        id="form-address" 
                        placeholder="Addess, City, State, Zipcode" 
                        value={ this.state.address }
                        onChange={ address => this.setState({ address: address.target.value }) }
                      />
                    </div>
                    <div className="col-xs-12 col-sm-4 contact-page__body-freeinspection-form-col contact-page__body-freeinspection-form-phone">
                      <PhoneInput
                        country="US"
                        placeholder="Phone"
                        value={ this.state.phone }
                        onChange={ phone => this.setState({ phone }) } 
                      />
                    </div>
                    <div className="col-xs-12 col-sm-4 contact-page__body-freeinspection-form-col">
                      <input
                        type="text"
                        id="form-email"
                        placeholder="Email"
                        value={ this.state.email }
                        onChange={ email => this.setState({ email: email.target.value }) }
                      />
                    </div>
                  </div>

                  <div className="row contact-page__body-freeinspection-form-row">
                    <div className="col-xs-12 contact-page__body-freeinspection-form-col">
                       <input
                          type="text"
                          id="form-notes"
                          placeholder="Notes (optional)"
                          value={ this.state.notes }
                          onChange={ notes => this.setState({ notes: notes.target.value }) }
                        />
                      </div>
                  </div>

                  <div className="contact-page__body-freeinspection-form-policy">
                    By submitting this form, you are agreeing to the <Link to='/privacypolicy'>privacy
                    policy</Link>.
                  </div>

                  <div id="my-recaptcha-placeholder"></div>

                  {this.state.submitButtonStatus === 1 && <div className="emerald-button-disabled contact-page__body-freeinspection-form-contact-btn contact-page__body-freeinspection-form-contact-btn-disabled">
                    <div className="emerald-button-content">
                      Contact Us
                    </div>
                  </div>}

                  {this.state.submitButtonStatus === 2 && <div className="emerald-button contact-page__body-freeinspection-form-contact-btn" onClick={this.submitContactInfo}>
                    <div className="emerald-button-content">
                      <span>Contact Us</span>
                    </div>
                  </div>}

                  {this.state.submitButtonStatus === 3 && <div className="emerald-button contact-page__body-freeinspection-form-contact-btn contact-page__body-freeinspection-form-contact-btn-loading">
                    <div className="emerald-button-content">
                      Submitting &nbsp;<span className="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span>
                    </div>
                  </div>}

                  {this.state.error && <div className="contact-page__body-freeinspection-form-error">
                    {this.state.error}
                  </div>}

                  {this.state.success && <div className="contact-page__body-freeinspection-form-success">
                    {this.state.success}
                  </div>}

                </div>
              </div>
            </Element>

            <Element name="locations">
              <div className="contact-page__body-locations">
                <h1>Locations</h1>
                <div className="contact-page__body-locations-content">
                  We service the following cities around St. George and more!
                  Enter your address below to see if we service your area.
                </div>

                <div className="contact-page__body-locations-list">
                  <div className="row">
                    <div className="col-xs-6">
                      <ul>
                        <li>St. George, UT</li>
                        <li>Ivins, UT</li>
                        <li>Santa Clara, UT</li>
                      </ul>
                    </div>
                    <div className="col-xs-6">
                      <ul>
                        <li>Toquerville, UT</li>
                        <li>Hurricane, UT</li>
                        <li>Dammeron Valley, UT</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="contact-page__google-maps-container">
                  <GoogleMaps scroll={this.scrollToFreeInspection}/>
                </div>

              </div>
            </Element>

            <Element name="offers">
              <div className="contact-page__body-offers">
                <h1>Special Offers</h1>
                <div className="contact-page__body-offers-content">
                  Not only will we eliminate all your pest troubles, but we can
                  save you money while you do it! Join our Emerald family and
                  sign up for any of our special offers that interest you!
                </div>

                <div className="contact-page__body-discounts">
                  <div className="row">
                    <div className="col-xs-12 col-sm-4">
                      <div className="contact-page__body-offers-discount">
                        <div className="contact-page__body-offers-discount-header">
                          First timers 50%
                        </div>
                        <div className="contact-page__body-offers-discount-content">
                          Join our Emerald family and receive 1/2 off on the
                          first treatment!
                        </div>
                        
                        {this.state.offer1 === 1 && <div className="contact-page__body-offers-discount-redeem">
                          <div className="emerald-button contact-page__body-offers-discount-redeem-btn" onClick={ () => this.setState({ offer1: 2}) }>
                            <div className="emerald-button-content">
                              <span>Redeem</span>
                            </div>
                          </div>
                        </div>}

                        {this.state.offer1 === 2 && <div className="contact-page__body-offers-discount-redeem">
                          <input
                              value={this.state.offer1email}
                              onChange={(evt) => { this.setState({ offer1email: evt.target.value}) }} 
                              type="text"
                              className="contact-page__body-offers-discount-redeem-input"
                              placeholder="Email"
                              onKeyPress={ (evt) => this.handleKeyPress(evt, 1) }
                            />
                            <div className="emerald-button contact-page__body-offers-discount-redeem-arrow-btn" onClick={ () => this.sendOffer(1) }>
                              <div className="emerald-button-content">
                                <span className="glyphicon glyphicon-arrow-right" />
                              </div>
                            </div>
                        </div>}

                        {this.state.offer1 === 3 && <div className="contact-page__body-offers-discount-redeem">
                          <div className="contact-page__body-offers-discount-redeem-sent"> 
                            Offer sent to email!
                          </div>
                        </div>}


                        {this.state.offer1error && <div className="contact-page__body-offers-discount-redeem">
                          <div className="contact-page__body-offers-discount-redeem-error"> 
                            Please enter a valid email.
                          </div>
                        </div>}

                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-4">
                      <div className="contact-page__body-offers-discount">
                        <div className="contact-page__body-offers-discount-header">
                          10% OFF WHOLE YEAR
                        </div>
                        <div className="contact-page__body-offers-discount-content">
                          Pay 90% of the whole year upfront for a full year of
                          treatment.
                        </div>

                        {this.state.offer2 === 1 && <div className="contact-page__body-offers-discount-redeem">
                          <div className="emerald-button contact-page__body-offers-discount-redeem-btn" onClick={ () => this.setState({ offer2: 2}) }>
                            <div className="emerald-button-content">
                              <span>Redeem</span>
                            </div>
                          </div>
                        </div>}

                        {this.state.offer2 === 2 && <div className="contact-page__body-offers-discount-redeem">
                          <input
                              value={this.state.offer2email}
                              onChange={(evt) => { this.setState({ offer2email: evt.target.value}) }} 
                              type="text"
                              className="contact-page__body-offers-discount-redeem-input"
                              placeholder="Email"
                              onKeyPress={ (evt) => this.handleKeyPress(evt, 2) }
                            />
                            <div className="emerald-button contact-page__body-offers-discount-redeem-arrow-btn" onClick={ () => this.sendOffer(2) }>
                              <div className="emerald-button-content">
                                <span className="glyphicon glyphicon-arrow-right" />
                              </div>
                            </div>
                        </div>}

                        {this.state.offer2 === 3 && <div className="contact-page__body-offers-discount-redeem">
                          <div className="contact-page__body-offers-discount-redeem-sent"> 
                            Offer sent to email!
                          </div>
                        </div>}


                        {this.state.offer2error && <div className="contact-page__body-offers-discount-redeem">
                          <div className="contact-page__body-offers-discount-redeem-error"> 
                            Please enter a valid email.
                          </div>
                        </div>}


                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-4">
                      <div className="contact-page__body-offers-discount">
                        <div className="contact-page__body-offers-discount-header">
                          Refer a Friend
                        </div>
                        <div className="contact-page__body-offers-discount-content">
                          Every time you refer a friend, both of you will get
                          50% off from the next treatment.
                        </div>

                        {this.state.offer3 === 1 && <div className="contact-page__body-offers-discount-redeem">
                          <div className="emerald-button contact-page__body-offers-discount-redeem-btn" onClick={ () => this.setState({ offer3: 2}) }>
                            <div className="emerald-button-content">
                              <span>Redeem</span>
                            </div>
                          </div>
                        </div>}

                        {this.state.offer3 === 2 && <div className="contact-page__body-offers-discount-redeem">
                          <input
                              value={this.state.offer3email}
                              onChange={(evt) => { this.setState({ offer3email: evt.target.value}) }} 
                              type="text"
                              className="contact-page__body-offers-discount-redeem-input"
                              placeholder="Email"
                              onKeyPress={ (evt) => this.handleKeyPress(evt, 3) }
                            />
                            <div className="emerald-button contact-page__body-offers-discount-redeem-arrow-btn" onClick={ () => this.sendOffer(3) }>
                              <div className="emerald-button-content">
                                <span className="glyphicon glyphicon-arrow-right" />
                              </div>
                            </div>
                        </div>}

                        {this.state.offer3 === 3 && <div className="contact-page__body-offers-discount-redeem">
                          <div className="contact-page__body-offers-discount-redeem-sent"> 
                            Offer sent to email!
                          </div>
                        </div>}


                        {this.state.offer3error && <div className="contact-page__body-offers-discount-redeem">
                          <div className="contact-page__body-offers-discount-redeem-error"> 
                            Please enter a valid email.
                          </div>
                        </div>}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Element>

            <Element name="write">
              <div className="contact-page__body-reviews">
                <h1>Write a Review</h1>
                <div className="contact-page__body-reviews-content">
                  We appreciate the opportunity to be your one-stop-shop for all
                  your pest control needs. To help us continue the highest
                  quality of service, we invite you to leave feedback on any (or
                  all!) of the platforms below. Thank you for your time and we
                  hope we see you again soon!
                </div>

                <div className="contact-page__body-reviews-links">
                  <a
                    href="https://www.google.com/search?client=safari&hl=en-us&q=Emerald+Pest+Control&ludocid=14280638606876291224&kgs=4cd2e4d47aec7591&shndl=-1&source=sh/x/kp/local&entrypoint=sh/x/kp/local#fpstate=lie&lrd=0x80ca4162ae6dcb65:0xc62f0526a0973098,1,,,"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Google} alt="google" />
                  </a>
                  <a
                    href="https://www.yelp.com/biz/emerald-pest-control-st-george-2"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Yelp} alt="yelp" />
                  </a>
                  <a
                    href="https://www.facebook.com/EmeraldPestControl/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Facebook} alt="facebook" />
                  </a>
                </div>
              </div>
            </Element>

            <Element name="license">
              <div className="contact-page__body-license">
                <h1>Business License</h1>
                <div className="contact-page__body-license-content">
                  Business License: 4000-2152
                </div>
                

               
              </div>
            </Element>





          </div>
        </div>
      </div>
    );
  }
}
