import React from 'react';
import { Link } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import {
  Element,
  animateScroll as scroll,
  scroller,
} from 'react-scroll';

import './FiveStep.scss';

class FiveStep extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  scrollDown() {
    scroller.scrollTo('fivesteps', {
      duration: 500,
      smooth: true,
    });
  }

  render() {
    return (
      <div className="fivestep-container">
        <div className="fivestep-container__icon">
          <img alt='spray-can-icon' src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBoZWlnaHQ9IjY0cHgiIHZpZXdCb3g9Ii0xMTUgMCA1MTIgNTEyIiB3aWR0aD0iNjRweCI+PHBhdGggZD0ibTIwNi40ODQzNzUgMjguODM1OTM4aDI5LjV2MjkuNWgtMjkuNXptMCAwIiBmaWxsPSIjMDAwMDAwIi8+PHBhdGggZD0ibTI1MS45ODQzNzUgMjguODM1OTM4aDI5LjV2MjkuNWgtMjkuNXptMCAwIiBmaWxsPSIjMDAwMDAwIi8+PHBhdGggZD0ibTE1Ny4xMjEwOTQgMTA1LjMzMjAzMWgtMjUuMDg1OTM4di0xOC4xNjAxNTZoMjkuMDg1OTM4di0yOC41ODU5MzdoMjQuNDE0MDYydi0zMGgtMjQuNDE0MDYydi0yOC41ODU5MzhoLTg3LjE3MTg3NXY4Ny4xNzE4NzVoMjguMDg1OTM3djE4LjE2MDE1NmgtMjUuMDg5ODQ0Yy0zNS4wMTk1MzEgMC02My42NDA2MjQgMjcuODQzNzUtNjQuOTM3NSA2Mi41NTQ2ODhoMjEwLjA1NDY4OGMtMS4yOTY4NzUtMzQuNzEwOTM4LTI5LjkxNzk2OS02Mi41NTQ2ODgtNjQuOTQxNDA2LTYyLjU1NDY4OHptMCAwIiBmaWxsPSIjMDAwMDAwIi8+PHBhdGggZD0ibTE1Ni4wMzUxNTYgMjkyaDc4LjAzNTE1NnYxMDMuODE2NDA2aC03OC4wMzUxNTZ6bTAgMCIgZmlsbD0iIzAwMDAwMCIvPjxwYXRoIGQ9Im0wIDUxMmgyMzQuMDcwMzEydi04Ni4xODM1OTRoLTEwOC4wMzUxNTZ2LTE2My44MTY0MDZoMTA4LjAzNTE1NnYtNjQuMTEzMjgxaC0yMzQuMDcwMzEyem0wIDAiIGZpbGw9IiMwMDAwMDAiLz48L3N2Zz4K" />
        </div>
        <div className="fivestep-container__header-border-top" />
        <div className="fivestep-container__header">THE 5-STEP TREATMENT</div>
        <div className="fivestep-container__header-border-bottom" />

        <div className="fivestep-container__subtext">
          With our trusted professionals with many years of experience, Emerald
          thrives to give you and your family a safe and comfortable home free
          of stress from pests. We also provide the safest treatment for your
          kids and pets. And if the pests ever come back, we do too.
        </div>

        <div className="fivestep-container__down-arrow">
          <span
            className="glyphicon glyphicon-menu-down"
            onClick={this.scrollDown}
          />
        </div>

        <Element name="fivesteps">
          <div className="fivestep-container__five-steps">
            <div className="row fivestep-container__first-row">
              <div className="col-xs-12 col-sm-6 col-lg-4 fivestep-container__col fivestep-container__col-1">
                <div className="fivestep-container__step-power-spray fivestep-container__single-step">
                  <div className="fivestep-container__step-content">
                    <div className="fivestep-container__step-header">
                      POWER SPRAY
                    </div>
                    <div className="fivestep-container__step-description">
                      The micro-encapsulated spray is time released and soaks
                      into the ground reaching pests where they live and breed,
                      killing off the nests completely and preventing them from
                      relocating.
                    </div>
                    <Link
                      className="reactdomlink"
                      to="/treatments#Services-powerspray"
                    >
                      <div className="emerald-button fivestep-container__learn-more-btn">
                        <div className="emerald-button-content">
                          <span>Learn more</span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-lg-4 fivestep-container__col fivestep-container__col-2">
                <div className="fivestep-container__step-granules fivestep-container__single-step">
                  <div className="fivestep-container__step-content">
                    <div className="fivestep-container__step-header">
                      GRANULES
                    </div>
                    <div className="fivestep-container__step-description">
                      Applying granules to the yard treats the pests that near
                      the base of the house and IN the grass and yard. We
                      granulate up to 20 feet at EVERY SERVICE using our premium
                      waterproof and SAFE granules.
                    </div>
                    <Link
                      className="reactdomlink"
                      to="/treatments#Services-granules"
                    >
                      <div className="emerald-button fivestep-container__learn-more-btn">
                        <div className="emerald-button-content">
                          <span>Learn more</span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-lg-4 fivestep-container__col fivestep-container__col-3">
                <div className="fivestep-container__step-dust-injections fivestep-container__single-step">
                  <div className="fivestep-container__step-content">
                    <div className="fivestep-container__step-header">
                      DUST-INJECTIONS
                    </div>
                    <div className="fivestep-container__step-description">
                      Our dust quickly spreads to even the smallest spaces
                      outside and inside block walls with power outlets where
                      liquids can’t, killing any unwanted pests and preventing
                      them from returning.
                    </div>
                    <Link
                      className="reactdomlink"
                      to="/treatments#Services-dustinjections"
                    >
                      <div className="emerald-button fivestep-container__learn-more-btn">
                        <div className="emerald-button-content">
                          <span>Learn more</span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row fivestep-container__second-row">
              <div className="col-xs-12 col-sm-6 col-lg-6 fivestep-container__col fivestep-container__col-4">
                <div className="fivestep-container__step-garage fivestep-container__single-step">
                  <div className="fivestep-container__step-content">
                    <div className="fivestep-container__step-header">
                      360 INTERIOR + GARAGE
                    </div>
                    <div className="fivestep-container__step-description">
                      Our interior service treats with pinpoint accuracy 360
                      degrees surrounding the every interior room of the house
                      AND garage so that your entire home will be pest free.
                    </div>
                    <Link
                      className="reactdomlink"
                      to="/treatments#Services-360interior"
                    >
                      <div className="emerald-button fivestep-container__learn-more-btn">
                        <div className="emerald-button-content">
                          <span>Learn more</span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-lg-6 fivestep-container__col fivestep-container__col-5">
                <div className="fivestep-container__step-dust-deweb fivestep-container__single-step">
                  <div className="fivestep-container__step-content">
                    <div className="fivestep-container__step-header">
                      DE-WEB
                    </div>
                    <div className="fivestep-container__step-description">
                      Every visit we provide web and nest knockdown near the
                      highest and lowest points. Our equipment can reach up to
                      three stories high to ensure the removal of all webs and
                      nests.
                    </div>
                    <Link
                      className="reactdomlink"
                      to="/treatments#Services-deweb"
                    >
                      <div className="emerald-button fivestep-container__learn-more-btn">
                        <div className="emerald-button-content">
                          <span>Learn more</span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Element>
      </div>
    );
  }
}

export default FiveStep;
