import styled from 'styled-components';

export default styled.div`
	background-color: white;
	width: 1px;
	height: 20px;
	display: inline-block;
	position: absolute;
	top: 14px;
`;
