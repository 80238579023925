import React from 'react';
import './Review.scss';

import GoogleRating from '../GoogleRating';
import FacebookRating from '../FacebookRating';
import PestReviewRating from '../PestReviewRating';

class Review extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    // !function() {
    //   function e() { 
    //       let escript=document.createElement('script'); 
    //       escript.type = 'text/javascript';
    //       escript.async = !0;
    //       escript.src = 'https://embed.pestroutes.com/embeddableWidgets/reviewsBadge/emeraldpestcontrol-stg?orientation=landscape&color=light'; 
    //       escript.id = 'pestroutesscript';
        
    //       let t = document.getElementsByTagName('script')[0]; 
    //       t.parentNode.insertBefore(escript,t);     
    //   } 

    //   'complete' === document.readyState ? e() : window.attachEvent ? window.attachEvent('onload',e) : window.addEventListener('load',e,!1);

    // }(); 
  }

  componentWillUnmount() {
    // var element = document.getElementById('pestroutesscript');
    // element.parentNode.removeChild(element);
  }

  render() {
    return (
      <div className="review-container">
        <div className="review-container__stars">
          <span className="glyphicon glyphicon-star" />
          <span className="glyphicon glyphicon-star" />
          <span className="glyphicon glyphicon-star" />
          <span className="glyphicon glyphicon-star" />
          <span className="glyphicon glyphicon-star" />
        </div>
        <div className="review-container__review">
          <div className="review-container__review-text">
            "I was originally with a different pest control company for scorpions
            but they couldn’t get rid of them. So I switched to Emerald pest
            control and it has been really great because I haven’t seen any more
            scorpions inside or around the house. Great service, accommodating,
            efficient and reliable. Thank you!" &mdash; Emma N.
            <div className="review-container__review-emma"></div>
          </div>
        
        </div>

        <div className='review-container__ratings'>
          <div className='review-container__ratings-pestroutes'>
            <PestReviewRating />
             </div>
          <div className='review-container__ratings-google'>
            <GoogleRating />
          </div>
          <div className='review-container__ratings-facebook'>
            <FacebookRating />
          </div>
        </div>

      </div>
    );
  }
}

export default Review;
