import React from 'react';
import './FixedMobileCTA.scss';
import { Link } from 'react-router-dom'


class MobileFooter extends React.Component {

  render() {

    return (
      <div className='small-mobile-only'>
        <div className='fixed-mobile-cta'>
          
          <Link onClick={() => this.props.hashChange('/contact#GetFreeInspection')}  to='/contact#GetFreeInspection'>
            <div className='emerald-button fixed-mobile-cta__btn fixed-mobile-cta__btn-inspection'>
              <div className='emerald-button-content fixed-mobile-cta__btn-content'>
                <span>Get a free inspection!</span>
              </div>
            </div>
          </Link>
          
          <a href='tel:+14356191633'>
            <div className='emerald-button fixed-mobile-cta__btn fixed-mobile-cta__btn-call'>
              <div className='emerald-button-content fixed-mobile-cta__btn-content'>
                <span className="glyphicon glyphicon-earphone" />
                <span>435-619-1633</span>
              </div>
            </div>
          </a>
        </div>
      </div>
    );
  }
}

export default MobileFooter;
