import React from 'react';
import { Link } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import {
  Element,
  animateScroll as scroll,
  scroller,
} from 'react-scroll';

import NavBar from 'components/NavBar';

import './AboutPage.scss';

import BradyPic from './images/brady.jpg';
import GoogleIcon from './images/google.png';
import FacebookIcon from './images/facebook.png';
import PestReviewsIcon from './images/pestreviews.gif';

export default class AboutPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.scrollListener = null;

    this.handleScroll = this.handleScroll.bind(this);

    this.scrollToTopOfPage = this.scrollToTopOfPage.bind(this);
    this.scrollToMeetTheOwner = this.scrollToMeetTheOwner.bind(this);
    this.scrollToWhyEmerald = this.scrollToWhyEmerald.bind(this);
    this.scrollToReviews = this.scrollToReviews.bind(this);
    this.scrollToFaq = this.scrollToFaq.bind(this);
  }

  handleScroll() {
    this.setState({ scroll: window.scrollY });
  }

  componentDidMount() {
    const el = document.querySelector('.about-page__body-left');
    this.setState({
      top: el.offsetTop - 70,
      height: el.offsetHeight,
    });

    window.addEventListener('scroll', this.handleScroll, false);

    let path = window.location.hash;

    switch(path) {
      case '#WhyEmerald':
        this.scrollToWhyEmerald();
        break;
      case '#Reviews':
        this.scrollToReviews();
        break;
      case '#FAQ':
        this.scrollToFaq();
        break;
      default: 
        this.scrollToTopOfPage();
        break;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.hash !== prevProps.hash) {
      if(this.props.hash) {
        let hash = this.props.hash.split('#')[1];
        switch(hash) {
          case 'WhyEmerald':
            this.scrollToWhyEmerald();
            break;
          case 'Reviews':
            this.scrollToReviews();
            break;
          case 'FAQ':
            this.scrollToFaq();
            break;
          default:
            this.scrollToTopOfPage();
            break;
        }
      }
    }
  }


  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, false);

  }

  scrollToTopOfPage() {
    scroll.scrollToTop({duration: 0});
    this.setState({
      meetOwner: true,
      whyEmerald: false,
      reviews: false,
      faq: false,
    }); 
  }

  scrollToMeetTheOwner() {
    window.location.hash = '#MeetOurOwner';
    scroller.scrollTo('meetOwner', {
      duration: 500,
      smooth: false,
      offset: -70,
    });

    this.setState({
      meetOwner: true,
      whyEmerald: false,
      reviews: false,
      faq: false,
    });
  }

  scrollToWhyEmerald() {
    window.location.hash = '#WhyEmerald';
    scroller.scrollTo('whyEmerald', {
      duration: 500,
      smooth: false,
      offset: -70,
    });

    this.setState({
      meetOwner: false,
      whyEmerald: true,
      reviews: false,
      faq: false,
    });
  }

  scrollToReviews() {
    window.location.hash = '#Reviews';
    scroller.scrollTo('reviews', {
      duration: 500,
      smooth: false,
      offset: -70,
    });

    this.setState({
      meetOwner: false,
      whyEmerald: false,
      reviews: true,
      faq: false,
    });
  }

  scrollToFaq() {
    window.location.hash = '#FAQ';
    scroller.scrollTo('faq', {
      duration: 500,
      smooth: false,
      offset: -70,
    });

    this.setState({
      meetOwner: false,
      whyEmerald: false,
      reviews: false,
      faq: true,
    });
  }

  render() {
    const { scroll, top } = this.state;

    let menuClass = 'about-page__body-menu';
    menuClass += scroll > top ? ' about-page__body-menu-fixed' : '';

    let meetOwnerClass = 'about-page__body-menu-link';
    meetOwnerClass += this.state.meetOwner ? ' about-page__body-menu-link-active' : '';

    let whyEmeraldClass = 'about-page__body-menu-link';
    whyEmeraldClass += this.state.whyEmerald ? ' about-page__body-menu-link-active' : '';

    let reviewsClass = 'about-page__body-menu-link';
    reviewsClass += this.state.reviews ? ' about-page__body-menu-link-active' : '';

    let faqClass = 'about-page__body-menu-link';
    faqClass += this.state.faq ? ' about-page__body-menu-link-active' : '';

    return (
      <div className="about-page">
        <NavBar nav={['Home', 'About Emerald']} />

        <div className="about-page__body">
          <div className="about-page__body-left">
            <div className={menuClass}>
              <div className={meetOwnerClass} onClick={this.scrollToMeetTheOwner}>Meet the Owner</div>
              <div className={whyEmeraldClass} onClick={this.scrollToWhyEmerald}>Why Emerald</div>
              <div className={reviewsClass} onClick={this.scrollToReviews}>Reviews</div>
              <div className={faqClass} onClick={this.scrollToFaq}>FAQ</div>
              <div
                className="about-page__body-menu-backtop"
                onClick={this.scrollToTopOfPage}
              >
                &#8593; Back to top
              </div>

              <div className="about-page__body-menu-special-offers">
                Special Offers
              </div>

              <div className="about-page__body-menu-discount">
                <div className="about-page__body-menu-discount-header">
                  First timers 50% DISCOUNT
                </div>
                <div className="about-page__body-menu-discount-content">
                  Come join our Emerald family and receive 1/2 off on the first
                  treatment!
                </div>
                <div className="about-page__body-menu-discount-redeem">
                  <Link to='/contact#SpecialOffers'> 
                    <div className="emerald-button about-page__body-menu-discount-redeem-btn">
                      <div className="emerald-button-content">
                        <span>Redeem</span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div className="about-page__body-menu-discount-more">
                <Link to='/contact#SpecialOffers' className='about-page__body-menu-discount-more'> See More Offers!</Link>
              </div>
            </div>
          </div>

          <div className="about-page__body-right">
            <Element name="meetOwner">
              <div className="about-page__body-meet-owner">
                <h1>Meet the Owner</h1>
                <div className="about-page__body-meet-owner-content">
                  <img alt='brady-pic' src={BradyPic} />
                  <strong>Brady Barlow </strong>is the founder and owner of
                  Emerald Pest Control. With over 10 years of experience across
                  4 neighboring states, nobody in the industry knows pests
                  native to Southern Utah better. Born and raised in Saint
                  George, he knows this town, its people, and its pests. When
                  you choose Emerald, you deal directly with the owner - no
                  middle man, no sales pitches, and no inexperienced
                  technicians.
                </div>
              </div>
            </Element>

            <Element name="whyEmerald">
              <div className="about-page__body-why-emerald">
                <h1>Why Emerald?</h1>
                <div className="about-page__body-why-emerald-content">
                  At Emerald, we use only the highest quality product available
                  on the market and apply them with state of the art equipment.
                  We provide a 3-point barrier treating 3 feet up and 3 feet
                  out, keeping pests not only out of your home but AWAY from it.
                  We don't cut corners - No diluting. No cheap product. We go
                  the extra mile, offering specialty services that most
                  companies do not have the experience, knowledge or equipment
                  to perform. These specialty services help target specific
                  pests, eliminating them faster, safer, and more effectively.
                </div>
              </div>
            </Element>

            <Element name="reviews">
              <div className="about-page__body-reviews">
                <h1>Reviews</h1>
                <div className="about-page__body-reviews-content">
                  <div className="about-page__body-reviews-content-review">
                    <i>
                      I had a serious ant problem in my kitchen that I had tried fighting 
                      for a week or two. After calling several places, that told me they were 
                        weeks out, Brady arranged his schedule so he could come the next day. When 
                      we found a huge ant farm between treatments he was willing to come the next 
                      day to spray with no extra charge and still came out for our regularly scheduled 
                      spray after. We've been really impressed with Brady. St. George is growing so big 
                      that it's been hard to find companies with a personal interest in you. Brady has 
                      a small hometown feel in a big town."{' '}
                    </i>- &#9733;&#9733;&#9733;&#9733;&#9733; -{' '}
                    <strong> Kristin R.</strong>
                    <div className='about-page__body-reviews-content-review-profile about-page__body-reviews-content-review-profile-kristin'></div>
                  </div>

                  <div className="about-page__body-reviews-content-review-border" />

                  <div className="about-page__body-reviews-content-review">
                    <i>
                      "Brady is very thorough and made sure I understood exactly
                      what the pest control treatment does and how it works.
                      He's also a very friendly person and makes sure I'm
                      completely satisfied with my experience before he leaves.
                      I'm happy to say that I haven't had any issues with bugs
                      since Brady has been servicing my house. I'd recommend
                      Emerald Pest Control to everyone in the St. George area
                      who wants bang for his/her buck."{' '}
                    </i>- &#9733;&#9733;&#9733;&#9733;&#9733; -{' '}
                    <strong>Pasindu W.</strong>
                    <div className='about-page__body-reviews-content-review-profile about-page__body-reviews-content-review-profile-pasindu'></div>
                  </div>

                  <div className="about-page__body-reviews-content-review-border" />

                  <div className="about-page__body-reviews-content-review">
                    <i>
                      {' '}
                      We have been so pleased with Emerald Pest Control! We
                      first moved into our house and it was infested with
                      cockroaches. We tried to get rid of them ourselves, but we
                      didn’t have any luck. We called Brady, and have not had to
                      worry about any bugs in our house since.{' '}
                    </i>- &#9733;&#9733;&#9733;&#9733;&#9733; -{' '}
                    <strong>Rosie D.</strong>
                    <div className='about-page__body-reviews-content-review-profile about-page__body-reviews-content-review-profile-rosie'></div>
                  </div>

                  <div className="about-page__body-reviews-content-end">
                    <strong>
                      Feel free to read more of our reviews on Facebook, 
                      Google, and Pest Reviews!
                    </strong>
                  </div>

                  <div>
                    <a href='https://www.facebook.com/pg/EmeraldPestControl/reviews' rel="noopener noreferrer" target="_blank">
                      <img
                        className="about-page__body-reviews-content-facebook"
                        src={FacebookIcon}
                        alt="facebookicon"
                      />
                    </a>
                    <a href='https://www.google.com/maps?cid=14280638606876291224&authuser=3&_ga=2.164444159.131626298.1551805024-152003198.1551805024' rel="noopener noreferrer" target="_blank">
                      <img
                        className="about-page__body-reviews-content-google"
                        src={GoogleIcon}
                        alt="googleicon"
                      />
                    </a>
                    <a href='http://www.pestreviews.org/companies/emeraldpestcontrol-stg' rel="noopener noreferrer" target="_blank">
                      <img
                        className="about-page__body-reviews-content-pestreviews"
                        src={PestReviewsIcon}
                        alt="pestreviewsicon"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </Element>

            <Element name="faq">
              <div className="about-page__body-faq">
                <h1>FAQ</h1>
                <div className="about-page__body-faq-content">
                  <div className="about-page__body-faq-content-question">
                    Do I have to sign up for an entire year?
                  </div>
                  <div className="about-page__body-faq-content-answer">
                    Yes. Emerald Pest Control wants to ensure that as a customer
                    you are fully satisfied with our service. The best way to do
                    that is to treat your home year-round on a bi-monthly basis
                    (every other month). By doing this, your home has the constant
                    barrier keep pests out of and away from your home. If needed,
                    we will always come back in between services for a touch-up
                    spray free of charge.
                  </div>

                  <div className="about-page__body-faq-content-question">
                    How often do I need my home sprayed?
                  </div>
                  <div className="about-page__body-faq-content-answer">
                    Our Goal is to give customers the best possible results.
                    Because our products are weather, time and water resistant,
                    they last between 60-90 days. Going anywhere past these time
                    frames allows for new pests to lay eggs and make new homes,
                    putting you back at square one. Coming back every 60-90 days
                    allows us to renew that barrier around your home to prevent
                    this from happening.
                  </div>

                  <div className="about-page__body-faq-content-question">
                    Do you guarantee your work?
                  </div>
                  <div className="about-page__body-faq-content-answer">
                    Yes. As long as your are using our service on a regular basis
                    we will come back to your home as many times in-between
                    services as needed until your pest problem is resolved and you
                    as a customer are completely happy.
                  </div>

                  <div className="about-page__body-faq-content-question">
                    Can I skip the winter months?
                  </div>
                  <div className="about-page__body-faq-content-answer">
                    A lot of people think that due to the cold weather pest
                    control isn't needed during the winter months because the bugs
                    die or “disappear”. This is actually false. During the winter,
                    pests find warm places to harbor and withstand the cold
                    climate, and what better place than your cozy, warm home. You
                    will find if you do to have pest services in the winter, you
                    will not see bugs simply because they are hiding in your walls
                    where its warm and safe, not because they aren’t there.
                  </div>

                  <div className="about-page__body-faq-content-question">
                    Why do I need a back-to-back spray when I first start?
                  </div>
                  <div className="about-page__body-faq-content-answer">
                    The back-to-back is one of the most important services you
                    will receive on your home all year. With the first visit we
                    will kill between 60-70% of the adult bugs nesting at your
                    home. Its important to follow up 30 days later so we can not
                    only kill the remaining 30-40%, but to also break up the
                    nesting cycles and breading patterns. The spray cannot
                    penetrate the egg sacs as well so it is important we come 30
                    days later to kill all the babies once they hatch.
                  </div>
                </div>
              </div>
            </Element>
          </div>
        </div>
      </div>
    );
  }
}
