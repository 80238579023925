import React from 'react';
import { Link } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import {
  Element,
  animateScroll as scroll,
  scroller,
} from 'react-scroll';

import NavBar from 'components/NavBar';

import './TreatmentPage.scss';

export default class TreatmentPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.scrollListener = null;

    this.handleScroll = this.handleScroll.bind(this);

    this.scrollToTopOfPage = this.scrollToTopOfPage.bind(this);

    this.scrollToServices = this.scrollToServices.bind(this);
    this.scrollToSpecialtyServices = this.scrollToSpecialtyServices.bind(this);
    this.scrollToKidAndPetFriendly = this.scrollToKidAndPetFriendly.bind(this);
  }

  handleScroll() {
    this.setState({ scroll: window.scrollY });
  }

  componentDidMount() {
    const el = document.querySelector('.treatment-page__body-left');
    this.setState({
      top: el.offsetTop - 70,
      height: el.offsetHeight,
    });

    window.addEventListener('scroll', this.handleScroll, false);

    const path = window.location.hash;

    switch (path) {
      case '#SpecialtyServices':
        this.scrollToSpecialtyServices();
        break;
      case '#KidAndPetFriendly':
        this.scrollToKidAndPetFriendly();
        break;
      case '#Services-powerspray':
        this.scrollToPowerspray();
        break;
      case '#Services-granules':
        this.scrollToGranules();
        break;
      case '#Services-dustinjections':
        this.scrollToDustinjections();
        break;
      case '#Services-360interior':
        this.scrollToGarage();
        break;
      case '#Services-deweb':
        this.scrollToDeweb();
        break;
      default:
        this.scrollToTopOfPage();
        break;
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.hash !== prevProps.hash) {
      if (this.props.hash) {
        const hash = this.props.hash.split('#')[1];
        switch (hash) {
          case 'SpecialtyServices':
            this.scrollToSpecialtyServices();
            break;
          case 'KidAndPetFriendly':
            this.scrollToKidAndPetFriendly();
            break;
          default:
            scroller.scrollTo('services', {
              smooth: false,
              offset: -70
            });
            this.setState({
              services: true,
              specialtyServices: false,
              kidAndPetFriendly: false,
            });
            break;
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, false);
  }

  scrollToTopOfPage() {
    scroll.scrollToTop({duration: 0});
    this.setState({
      services: true,
      specialtyServices: false,
      kidAndPetFriendly: false,
    });
  }

  scrollToServices() {
    window.location.hash = '#Services';
    scroller.scrollTo('services', {
      smooth: false,
      offset: -70
    });

    this.setState({
      services: true,
      specialtyServices: false,
      kidAndPetFriendly: false,
    });
  }

  scrollToPowerspray() {
    window.location.hash = '#Services-powerspray';
    scroller.scrollTo('powerspray', {
      smooth: false,
      offset: -70
    });

    this.setState({
      services: true,
      specialtyServices: false,
      kidAndPetFriendly: false,
    });
  }

  scrollToGranules() {
    window.location.hash = '#Services-granules';
    scroller.scrollTo('granules', {
      smooth: false,
      offset: -70
    });

    this.setState({
      services: true,
      specialtyServices: false,
      kidAndPetFriendly: false,
    });
  }

  scrollToDustinjections() {
    window.location.hash = '#Services-dustinjections';
    scroller.scrollTo('dustinjections', {
      smooth: false,
      offset: -70
    });

    this.setState({
      services: true,
      specialtyServices: false,
      kidAndPetFriendly: false,
    });
  }

  scrollToGarage() {
    window.location.hash = '#Services-360interior';
    scroller.scrollTo('garage', {
      smooth: false,
      offset: -70
    });

    this.setState({
      services: true,
      specialtyServices: false,
      kidAndPetFriendly: false,
    });
  }

  scrollToDeweb() {
    window.location.hash = '#Services-deweb';
    scroller.scrollTo('deweb', {
      smooth: false,
      offset: -70
    });

    this.setState({
      services: true,
      specialtyServices: false,
      kidAndPetFriendly: false,
    });
  }

  scrollToSpecialtyServices() {
    window.location.hash = '#SpecialtyServices';
    scroller.scrollTo('specialtyServices', {
      smooth: false,
      offset: -70
    });

    this.setState({
      services: false,
      specialtyServices: true,
      kidAndPetFriendly: false,
    });
  }

  scrollToKidAndPetFriendly() {
    window.location.hash = '#KidAndPetFriendly';
    scroller.scrollTo('kidAndPetFriendly', {
      smooth: false,
      offset: -70
    });

    this.setState({
      services: false,
      specialtyServices: false,
      kidAndPetFriendly: true,
    });
  }

  render() {
    const { scroll, top } = this.state;

    let menuClass = 'treatment-page__body-menu';
    menuClass += scroll > top ? ' treatment-page__body-menu-fixed' : '';

    let servicesClass = 'treatment-page__body-menu-link';
    servicesClass += this.state.services
      ? ' treatment-page__body-menu-link-active'
      : '';

    let specialtyServicesClass = 'treatment-page__body-menu-link';
    specialtyServicesClass += this.state.specialtyServices
      ? ' treatment-page__body-menu-link-active'
      : '';

    let kidAndPetFriendlyClass = 'treatment-page__body-menu-link';
    kidAndPetFriendlyClass += this.state.kidAndPetFriendly
      ? ' treatment-page__body-menu-link-active'
      : '';

    return (
      <div className="treatment-page">
        <NavBar nav={['Home', 'Our Treatments']} color="blue" />

        <div className="treatment-page__body">
          <div className="treatment-page__body-left">
            <div className={menuClass}>
              <div className={servicesClass} onClick={this.scrollToServices}>
                Services
              </div>
              <div
                className={specialtyServicesClass}
                onClick={this.scrollToSpecialtyServices}
              >
                Specialty Services
              </div>
              <div
                className={kidAndPetFriendlyClass}
                onClick={this.scrollToKidAndPetFriendly}
              >
                Kid and Pet Friendly
              </div>
              <div
                className="treatment-page__body-menu-backtop"
                onClick={this.scrollToTopOfPage}
              >
                &#8593; Back to top
              </div>

              <div className="treatment-page__body-menu-special-offers">
                Special Offers
              </div>

              <div className="treatment-page__body-menu-discount">
                <div className="treatment-page__body-menu-discount-header">
                  Refer a friend
                </div>
                <div className="treatment-page__body-menu-discount-content">
                  Every time you refer a friend, both of you will get 50% off
                  from the next treatment.
                </div>
                <div className="treatment-page__body-menu-discount-redeem">
                  <Link to='/contact#SpecialOffers'> 
                    <div className="emerald-button treatment-page__body-menu-discount-redeem-btn">
                      <div className="emerald-button-content">
                        <span>Redeem</span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div className="treatment-page__body-menu-discount-more">
                 <Link to='/contact#SpecialOffers' className="treatment-page__body-menu-discount-more"> See More Offers!</Link>
              </div>
            </div>
          </div>

          <div className="treatment-page__body-right">
            <Element name="services">
              <div className="treatment-page__body-services">
                <h1>Services</h1>
                <div className="treatment-page__body-services-intro">
                  At Emerald, we use only the highest quality product available 
                  on the market and apply them with state of the art equipment. 
                  We go the extra mile, offering specialty services that most 
                  companies do not have the experience, knowledge, or equipment to perform. 
                </div>

                <Element name="powerspray">
                  <div className="treatment-page__body-services-single treatment-page__body-services-powerspray">
                    <h4>Power Spray</h4>
                    <div className="treatment-page__body-services-single-content">
                      <div className="treatment-page__body-services-single-content-picture"></div>
                      By applying our spray to the exterior of your home using our
                      state of the art premium power-spray equipment, we are able
                      to not only treat the pests you see, but the ones you don’t.
                      The micro-encapsulated spray is time released and soaks into
                      the ground reaching pests where they live and breed, killing
                      off the nests completely and preventing them from
                      relocating. With 120 PSI, we use this specific sprayer for
                      the exterior only because of its ability to flush out the
                      nesting pests and egg sacs immediately while leaving a
                      barrier to prevent any future pests from harboring there.
                    </div>
                  </div>
                </Element>


                <Element name="granules">
                  <div className="treatment-page__body-services-single treatment-page__body-services-granules">
                    <h4>Granules</h4>
                    <div className="treatment-page__body-services-single-content">
                      <div className="treatment-page__body-services-single-content-picture treatment-page__body-services-single-content-picture-granules"></div>
                      At Emerald, we not only treat the exterior of your home at
                      the base with liquid product, we also use a special granule
                      that we apply to the yard and rock beds. We granulate from
                      front curb to back fence with our hydro activated granules
                      that kills pests living up to 6 inches below the surface of
                      the soil. By applying granules to the yard, we are able to
                      treat the pests that are not only nesting near the base of
                      the house, but actually IN the grass and yard. We granulate
                      up to 20 feet at EVERY SERVICE using our premium waterproof
                      and SAFE granules.
                    </div>
                  </div>
                </Element>

                <Element name="dustinjections">
                  <div className="treatment-page__body-services-single treatment-page__body-services-dustinjections">
                    <h4>Dust Injections</h4>
                    <div className="treatment-page__body-services-single-content">
                      <div className="treatment-page__body-services-single-content-picture treatment-page__body-services-single-content-picture-dustinjections"></div>
                      Many pests, including scorpions and black widows, nest inside 
                      walls both inside and outside, where it is cool in the summer and 
                      warm in the winter. Rather than leaving these unseen and dangerous pests 
                      alone, we provide an extra service that specifically targets these areas. Our 
                      dust quickly spreads to even the smallest spaces inside block walls and both interior 
                      and exterior power outlets where liquids can't, killing any unwanted pests and preventing 
                      them from returning. This dust has 6 month residual, is water proof, and sticks to 
                      the pests exoskeleton, causing them to inhale it immediately.
                    </div>
                  </div>
                </Element>

                <Element name="garage">
                  <div className="treatment-page__body-services-single treatment-page__body-services-garage">
                    <h4>360 Interior + Garage</h4>
                    <div className="treatment-page__body-services-single-content">
                      <div className="treatment-page__body-services-single-content-picture treatment-page__body-services-single-content-picture-garage"></div>
                      At Emerald Pest Control, family and safety are our top
                      priorities. We use only the safest, not the cheapest,
                      products. We mix with precise measurements and do not dilute
                      - keeping your family and pets SAFE and you PEST FREE. Our
                      interior service treats with pinpoint accuracy 360 degrees
                      surrounding the entire interior of the house AND garage. Our
                      product is odorless, stain free, and hypo-allergenic. Our
                      interior and exterior spray is micro encapsulated, meaning
                      it leaves microscopic capsules that are time released as
                      well as weather and water resistant.
                    </div>
                  </div>
                </Element>

                <Element name="deweb">
                  <div className="treatment-page__body-services-single treatment-page__body-services-deweb">
                    <h4>De-Web</h4>
                    <div className="treatment-page__body-services-single-content">
                      <div className="treatment-page__body-services-single-content-picture treatment-page__body-services-single-content-picture-deweb"></div>
                      While treating pests inside and out is key, we also treat
                      pests living ON your home. Every visit we provide FREE web
                      and nest knockdown near the highest and lowest points. These
                      nests and webs often contain pests and egg sacks that
                      haven't yet hatched. Our equipment can reach up to three
                      stories high to ensure the removal of all webs and nests.
                    </div>
                  </div>
                </Element>

              </div>
            </Element>

            <Element name="specialtyServices">
              <div className="treatment-page__body-services">
                <h1>Specialty Services</h1>
                <div className="treatment-page__body-services-intro">
                  We offer specialty services that go above and beyond the typical pest control company. These specialty services 
                  ensure that we maintain the highest quality of service in St. George to keep your home and yard pest free.
                </div>

                <div className="treatment-page__body-services-single treatment-page__body-services-blacklight">
                  <h4>Black Light Scorpion Treatment</h4>
                  <div className="treatment-page__body-services-single-content">
                    <div className="treatment-page__body-services-single-content-picture treatment-page__body-services-single-content-picture-blacklightscorpion"></div>
                    The Desert Hairy and Bark Scorpions are native to Southern
                    Utah. At Emerald we are highly educated on these predators
                    and have developed a treatment specifically to target them.
                    Because scorpions are most active at night, we perform a
                    nocturnal treatment using a combination of black light and
                    the highest quality product available on the market to
                    eliminate them from your property. Our premium Black Light
                    Scorpion Treatment is effective and thorough, getting and
                    keeping your scorpion problem under control.
                  </div>
                </div>

                <div className="treatment-page__body-services-single treatment-page__body-services-beeremoval">
                  <h4>Bee Removal</h4>
                  <div className="treatment-page__body-services-single-content">
                    <div className="treatment-page__body-services-single-content-picture treatment-page__body-services-single-content-picture-beehive"></div>
                    Having worked with professional bee keepers, Brady knows the
                    most effective and safe way to remove a bee hive from your
                    property. Bees travel for miles and miles to find the best
                    nectar to take back to their hive, so seeing bees on your
                    property does not mean you have a hive. Seeing a bee
                    infestation on your property can look two different ways: a
                    swarm or a hive. The difference between a hive and a swarm
                    is this: a hive is a home the bees have made in a hollow
                    space in your home, it is not just out in the open, and you
                    will see bees coming and going. Whereas a swarm is a clump
                    of hanging bees that has traveled, looking for a NEW home.
                    With the spread of Africanized bees higher than ever, if you
                    notice a swarm of bees on your property, there is a good
                    chance it is Africanized. These bees are very aggressive and
                    should not be provoked.
                  </div>
                </div>

                <div className="treatment-page__body-services-single treatment-page__body-services-antgel">
                  <h4>Ant Gel</h4>
                  <div className="treatment-page__body-services-single-content">
                    The most common place for ant infestations in the home is
                    the kitchen. At Emerald, we use a special product that is
                    designed specifically for ants that most other companies do
                    not use. It is a clear gel that can be used on counter tops,
                    in cupboards, pantries and around appliances - places where
                    liquid product is difficult to use because of the overspray
                    on foods and food preparation surfaces. This gel is a
                    non-repellant, meaning the ants cannot detect it and end up
                    taking it back to the colony where it infects the queen.
                  </div>
                </div>
              </div>
            </Element>

            <Element name="kidAndPetFriendly">
              <div className="treatment-page__body-kid">
                <h1>Kid and Pet Friendly</h1>
                <div className="treatment-page__body-kid-content">
                  You do not need to sacrifice your households health to control
                  your pests. At emerald pest control we use only the highest
                  grade natural and organic products. We always follow the label
                  to get the best results and safest outcome.
                </div>
              </div>
            </Element>
          </div>
        </div>
      </div>
    );
  }
}
