import React from 'react';
import { Link } from 'react-router-dom'; 
import {GoogleApiWrapper, Map, Polygon, Marker} from 'google-maps-react';
import Geocode from "react-geocode";

import './GoogleMaps.scss';

import geolib from 'geolib';

Geocode.setApiKey("AIzaSyB26SC0HTJv12QYjD4cEotO1dy7hQ3YGnY");


const style = {
  width: '98%',
  height: '360px',
  position: 'relative',
  margin: '0 auto',
  textAlign: 'center',
}

export class GoogleMaps extends React.Component {
  constructor() {
    super();

    this.state = {
      address: '',
      loading: null,
      error: null,
      valid: null,
      invalid: null,
      marker: null,
    };

    this.checkAddress = this.checkAddress.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.clickFreeInspection = this.clickFreeInspection.bind(this);

  }

  renderMarkers() {
    if (this.state.marker) {
        return (
          <Marker
            title={'this is your home'}
            name={'home'}
            position={this.state.marker} 
          />
        );
    } else {
        return null;
    }
  }

  checkAddress() {
    if(!this.state.address) {
      return false;
    }

    this.setState({
      loading: true,
      error: null,
      valid: null,
      invalid: null,
    }, () => {
        Geocode.fromAddress(this.state.address).then((response) => {
            const { lat, lng } = response.results[0].geometry.location;
            let valid = this.checkIfAddressIsInBounds(lat, lng);

            if (valid) {
                this.setState({
                  marker: { lat: lat, lng: lng },
                  loading: null, 
                  valid: true,
                  invalid: null,
                });
            } else {
                this.setState({
                  marker: { lat: lat, lng: lng },
                  loading: null, 
                  valid: null,
                  invalid: true
                });
            }
          },
          (error) => {
             this.setState({
                loading: false,
                error: true,
                valid: null,
                invalid: null,
                marker: null,
              });
          });
    });
  }

  checkIfAddressIsInBounds(lat, lng) {
    return geolib.isPointInside(
      {latitude: lat, longitude: lng},
      [
        {latitude: 37.24837, longitude: -113.17094},
        {latitude: 37.43071, longitude: -113.50516},
        {latitude: 37.36337,longitude: -113.69749},
        {latitude: 37.33842,longitude: -113.7346},
        {latitude: 37.33959,longitude: -113.76758}, 
        {latitude: 37.3211,longitude: -113.77858}, 
        {latitude: 37.28785,longitude: -113.80205}, 
        {latitude: 37.06039,longitude: -113.69245},
        {latitude: 37.0421,longitude: -113.59057},
        {latitude: 37.03843,longitude: -113.47508},
        {latitude:37.2034,longitude: -113.21952},
        {latitude: 37.21113,longitude: -113.20141},
        {latitude: 37.22514,longitude: -113.17987}
      ]
    );
  }

  handleChange(event) {
    this.setState({
      address: event.target.value
    });
  }

  handleKeyPress(event) {
    if(event.key === 'Enter'){
      this.checkAddress();
    }
  }

  clickFreeInspection() {
    // There's a scroll function
    if(this.props.scroll) {
      this.props.scroll();
    }
  }

  render() {
    const serviceArea = [
      {lat: 37.24837, lng: -113.17094},
      {lat: 37.43071, lng: -113.50516},
      {lat: 37.36337,lng: -113.69749},
      {lat: 37.33842,lng: -113.7346},
      {lat: 37.33959,lng: -113.76758}, 
      {lat: 37.3211,lng: -113.77858}, 
      {lat: 37.28785,lng: -113.80205}, 
      {lat: 37.06039,lng: -113.69245},
      {lat: 37.0421,lng: -113.59057},
      {lat: 37.03843,lng: -113.47508},
      {lat:37.2034,lng: -113.21952},
      {lat: 37.21113,lng: -113.20141},
      {lat: 37.22514,lng: -113.17987}
    ];

    return (
      <div className='google-maps-component'>
        <div className="google-maps-component__check-location">
          <div className="google-maps-component__check-location-description">Check to see if your home is covered in our area:</div>
          <input
            value={this.state.address}
            onChange={this.handleChange} 
            type="text"
            className="google-maps-component__check-location-input"
            placeholder="123 Main Street, St. George UT, 84765"
            name="location-finder"
            onKeyPress={this.handleKeyPress}
          />
          {!this.state.loading && <div className="emerald-button google-maps-component__check-btn" onClick={this.checkAddress}>
            <div className="emerald-button-content google-maps-component__check-btn-desktop">
              <span>Check Address</span>
            </div>
            <div className="emerald-button-content google-maps-component__check-btn-mobile">
              <span className="glyphicon glyphicon-arrow-right" />
            </div>
          </div>}
          {this.state.loading && <div className="emerald-button-loading google-maps-component__check-btn-loading">
            <div className="emerald-button-content">
              <span className="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span>
            </div>
          </div>}
        </div>
        
        {this.state.valid && <div className='google-maps-component__valid'>
          <span className='glyphicon glyphicon-ok-sign'></span>Good news, we service your area! 
          <br />
          <div className='google-maps-component__valid-link'>
            <Link onClick={this.clickFreeInspection} to='/contact#GetFreeInspection'>Get your free inspection!</Link>
          </div>
        </div>}

        {this.state.invalid && <div className='google-maps-component__invalid'>
          We apologize but we do not service your area yet. :( <br />Please reach out to us directly at <a href="tel:+14356191633"> 435-619-1633</a> and we will try our best to assist you.
        </div>}

        {this.state.error && <div className='google-maps-component__error'>
          Please enter a valid address.
        </div>}

        <Map 
          google={this.props.google} 
          style={style} 
          containerStyle={style}
          initialCenter={{lat: 37.237740, lng: -113.530710}}
          zoom={9.9}>

          <Polygon
            paths={serviceArea}
            strokeColor="green"
            strokeOpacity={0.8}
            strokeWeight={2}
            fillColor="green"
            fillOpacity={0.35} 
          />

          {this.renderMarkers()}

          
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyB26SC0HTJv12QYjD4cEotO1dy7hQ3YGnY'
})(GoogleMaps)
