import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import HomePage from 'containers/HomePage';
import AboutPage from 'containers/AboutPage';
import TreatmentPage from 'containers/TreatmentPage';
import PestPage from 'containers/PestPage';
import BlogPage from 'containers/BlogPage';
import ContactPage from 'containers/ContactPage';
import PrivacyPolicy from 'containers/PrivacyPolicy';

import Header from 'components/Header';
import FixedMobileCTA from 'components/FixedMobileCTA';
import Footer from 'components/Footer';

export default class App extends React.Component {

  constructor() {
    super();

    this.hashChange = this.hashChange.bind(this);

    this.state = {
      hash: null,
    }
  }

  hashChange(hash) {
    this.setState({
      hash
    }, () => {
      this.setState({
        hash: null,
      });
    });
  }

  render() {
    return (
      <div>
         <Router>
            <Header hashChange={this.hashChange}/>
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route path="/about" render={(props) => <AboutPage hash={this.state.hash} />} />
              <Route path="/treatments" render={(props) => <TreatmentPage hash={this.state.hash} />} />
              <Route path="/pests" render={(props) => <PestPage hash={this.state.hash} />} />
              <Route path="/blog" render={(props) => <BlogPage hash={this.state.hash} />} />
              <Route path="/contact" render={(props) => <ContactPage hash={this.state.hash} />} />
              <Route path="/privacypolicy" render={(props) => <PrivacyPolicy />} />
              <Route path="" component={HomePage} />
            </Switch>
            <FixedMobileCTA hashChange={this.hashChange} />
            <Footer hashChange={this.hashChange}/>
         </Router>
      </div>
    );
  }
}
