import styled from 'styled-components';

export default styled.div`
  display: none;

  // Only show this on (non mobiles)
  @media (min-width: 599px) {
    width: 100%;
    background-color: black;
    height: 50px;
    display: block;
    color: white !important;
    padding: 9px;
  }
`;
