import React from 'react';

import './GoogleRating.scss';

class GoogleRating extends React.Component {
  openNewTab() {
    window.open('https://www.google.com/search?q=emerald+pest+control+st+george&oq=emerald+pest+&aqs=chrome.0.69i59l2j69i61j69i60l2j69i57.1479j0j4&sourceid=chrome&ie=UTF-8#lrd=0x80ca4162ae6dcb65:0xc62f0526a0973098,1,,,','_blank');
  }

  render() {
    return (
      <div className='google-rating' onClick={this.openNewTab}>
        <div className='google-rating__container'>
            
            <div className='google-rating__logo'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="44" width="44"><g fill="none"><path d="M482.56 261.36c0-16.73-1.5-32.83-4.29-48.27H256v91.29h127.01c-5.47 29.5-22.1 54.49-47.09 71.23v59.21h76.27c44.63-41.09 70.37-101.59 70.37-173.46z" fill="#4285f4"></path><path d="M256 492c63.72 0 117.14-21.13 156.19-57.18l-76.27-59.21c-21.13 14.16-48.17 22.53-79.92 22.53-61.47 0-113.49-41.51-132.05-97.3H45.1v61.15c38.83 77.13 118.64 130.01 210.9 130.01z" fill="#34a853"></path><path d="M123.95 300.84c-4.72-14.16-7.4-29.29-7.4-44.84s2.68-30.68 7.4-44.84V150.01H45.1C29.12 181.87 20 217.92 20 256c0 38.08 9.12 74.13 25.1 105.99l78.85-61.15z" fill="#fbbc05"></path><path d="M256 113.86c34.65 0 65.76 11.91 90.22 35.29l67.69-67.69C373.03 43.39 319.61 20 256 20c-92.25 0-172.07 52.89-210.9 130.01l78.85 61.15c18.56-55.78 70.59-97.3 132.05-97.3z" fill="#ea4335"></path><path d="M20 20h472v472H20V20z"></path></g></svg>
            </div>
            
            <div className='google-rating__text'>
              <div className='google-rating__description'>Google Rating</div>
              <div className='google-rating__rating'>
                  <div className='google-rating__rating-number'>5</div>
                  <div className='google-rating__rating-stars'>
                    <span className="glyphicon glyphicon-star"></span>
                    <span className="glyphicon glyphicon-star"></span>
                    <span className="glyphicon glyphicon-star"></span>
                    <span className="glyphicon glyphicon-star"></span>
                    <span className="glyphicon glyphicon-star"></span>
                  </div>
              </div>
              <div className='google-rating__description-2'>50+ reviews</div>
            </div>
        </div>
      </div>  
    );
  }
}

export default GoogleRating;
