import React from 'react';
import _ from 'lodash';
import DesktopHeader from './DesktopHeader';
import HeaderLink from './HeaderLink';
import Separator from './Separator';
import { Link } from 'react-router-dom';

import Logo from './logo-inverted.png';
import './Header.scss';
import './Navbar.scss';

/* eslint-disable react/prefer-stateless-function */
class Header extends React.Component {
  constructor() {
    super();
    this.listenScrollEvent = this.listenScrollEvent.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.renderSubMenu = this.renderSubMenu.bind(this);
    this.renderSubMenuMobile = this.renderSubMenuMobile.bind(this);
    this.setSubMenu = this.setSubMenu.bind(this);
    this.clearMenu = this.clearMenu.bind(this);

    this.state = {
      headerBackground: false,
      showMenu: false,
      subMenu: null,
      logo: Logo,
      subMenuLinks: {
        about: [
          { id: 'Meet Our Owner', url: '/about#MeetOurOwner' },
          { id: 'Why Emerald?', url: '/about#WhyEmerald' },
          { id: 'Reviews', url: '/about#Reviews' },
          { id: 'FAQ', url: '/about#FAQ' },
        ],
        treatments: [
          { id: 'Services', url: '/treatments#Services' },
          { id: 'Specialty Services', url: '/treatments#SpecialtyServices' },
          { id: 'Kid and Pet Friendly', url: '/treatments#KidAndPetFriendly' },
        ],
        pests: [
          { id: 'Ants', url: '/pests#Ants' },
          { id: 'Bees', url: '/pests#Bees' },
          { id: 'Cockroaches', url: '/pests#Cockroaches' },
          { id: 'Crickets', url: '/pests#Crickets' },
          { id: 'Rodents', url: '/pests#Rodents' },
          { id: 'Scorpions', url: '/pests#Scorpions' },
          { id: 'Spiders', url: '/pests#Spiders' },
          { id: 'Termites', url: '/pests#Termites' },
        ],
      },
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent)
  }

  listenScrollEvent(e) {
    if (window.scrollY > 50) {
      this.setState({ headerBackground: true });
    } else {
      this.setState({ headerBackground: false });
    }
  }

  toggleMenu(s) {
    this.setState({ showMenu: !this.state.showMenu });
  }

  renderSubMenu() {
    const { subMenuLinks, subMenu } = this.state;

    return (
      <div className="navbar__sub-menu">
        <div className="arrow-up" />
        <ul>
          {_.map(subMenuLinks, (item, itemId) => {
            if (subMenu === itemId) {
              return _.map(item, link => (
                <HeaderLink
                  onClick={() => this.clearMenu(link.url)}
                  key={link.id}
                  to={link.url}
                >
                  <li>{link.id}</li>
                </HeaderLink>
              ));
            }
          })}
        </ul>
      </div>
    );
  }

  clearMenu(url) {
    this.props.hashChange(url);
    this.setState({
      showMenu: false,
      subMenu: null,
    });
  }

  setSubMenu(item, click = false) {
    if (click) {
      this.setState({ subMenu: item });
    } else if (this.state.subMenu === item) {
      this.setState({ subMenu: null });
    } else {
      this.setState({ subMenu: item });
    }
  }

  renderSubMenuMobile(item) {
    const { subMenuLinks, subMenu } = this.state;

    return (
      <div className="navbar__sub-menu-mobile">
        <div className="arrow-up" />
        <ul>
          {_.map(subMenuLinks, (item, itemId) => {
            if (subMenu === itemId) {
              return _.map(item, link => (
                <HeaderLink
                  onClick={() => {
                    this.clearMenu(link.url);
                  }}
                  key={link.id}
                  to={link.url}
                >
                  <li>{link.id}</li>
                </HeaderLink>
              ));
            }
          })}
        </ul>
      </div>
    );
  }

  render() {
    const { showMenu, subMenu } = this.state;

    let navbarClasses = 'navbar__items navbar__items-right';
    navbarClasses += showMenu ? ' navbar__toggle-show' : '';

    let headerClassname = this.state.headerBackground ? "siteheaderdefault desktop-fixed-header" : "siteheaderdefault";
    let fakeHeaderClassname = this.state.headerBackground ? "fake-header fake-header-fixed" : "fake-header";

    return (
      <div>
        <DesktopHeader>
          <div className="desktop-header">
            <div className="desktop-header-left" />

            <div className="desktop-header-right">
              <div className="desktop-header-right__phone">
                <a
                  href={`tel:+14356191633`}
                  className="desktop-header-right__phone-text"
                >
                  <span className="glyphicon glyphicon-earphone" />
                  435-619-1633
                </a>
              </div>
              <Separator />
              <div className="desktop-header-right__pay-bill">
                <a
                  href='https://www.pestroutes.com/'
                  target="_blank"
                  className="desktop-header-right__pay-bill-text"
                  rel="noopener noreferrer"
                >
                  <span className="glyphicon glyphicon-credit-card" />
                  PAY MY BILL
                </a>
              </div>
              <Separator />
              <div className="desktop-header-right__inspect">
                <Link to="/contact#GetFreeInspection">
                  <div className="emerald-button">
                    <div className="emerald-button-content">
                      <span>Get a free inspection</span>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </DesktopHeader>
        
        {<div className={fakeHeaderClassname}>
        </div>}

        <div className={headerClassname}>
          <div className="navbar">
            <div className="navbar__link-brand">
              <HeaderLink to="/">
                <div className='navbar__desktop-logo'></div>  
              </HeaderLink>
              <HeaderLink onClick={this.clearMenu} to="/">
                <div className='navbar__mobile-logo'></div>  
              </HeaderLink>
            </div>

            <div
              className="navbar__link navbar__link-toggle"
              onClick={this.toggleMenu}
            >
              {!showMenu && (
                <span className="glyphicon glyphicon-menu-hamburger" />
              )}
              {showMenu && <span className="glyphicon glyphicon-remove" />}
            </div>

            <nav className={navbarClasses}>
              <div
                className="navbar__link desktop-only"
                onMouseLeave={() => this.setSubMenu(null)}
                onMouseEnter={() => this.setSubMenu('about')}
              >
                <span>ABOUT</span>
                <span className="glyphicon glyphicon-menu-down" />
                {subMenu === 'about' && this.renderSubMenu()}
              </div>
              <div
                className="navbar__link mobile-only"
                onClick={() => this.setSubMenu('about')}
              >
                <span>ABOUT</span>
                {subMenu !== 'about' && (
                  <span className="glyphicon glyphicon-menu-down" />
                )}
                {subMenu === 'about' && (
                  <span className="glyphicon glyphicon-menu-up" />
                )}
                {subMenu === 'about' && this.renderSubMenuMobile()}
              </div>

              <div
                className="navbar__link desktop-only"
                onMouseLeave={() => this.setSubMenu(null)}
                onMouseEnter={() => this.setSubMenu('treatments')}
              >
                <span>TREATMENTS</span>
                <span className="glyphicon glyphicon-menu-down" />
                {subMenu === 'treatments' && this.renderSubMenu()}
              </div>
              <div
                className="navbar__link mobile-only"
                onClick={() => this.setSubMenu('treatments')}
              >
                <span>TREATMENTS</span>
                {subMenu !== 'treatments' && (
                  <span className="glyphicon glyphicon-menu-down" />
                )}
                {subMenu === 'treatments' && (
                  <span className="glyphicon glyphicon-menu-up" />
                )}
                {subMenu === 'treatments' && this.renderSubMenuMobile()}
              </div>

              <div
                className="navbar__link desktop-only"
                onMouseLeave={() => this.setSubMenu(null)}
                onMouseEnter={() => this.setSubMenu('pests')}
              >
                <span>PESTS</span>
                <span className="glyphicon glyphicon-menu-down" />
                {subMenu === 'pests' && this.renderSubMenu()}
              </div>
              <div
                className="navbar__link mobile-only"
                onClick={() => this.setSubMenu('pests')}
              >
                <span>PESTS</span>
                {subMenu !== 'pests' && (
                  <span className="glyphicon glyphicon-menu-down" />
                )}
                {subMenu === 'pests' && (
                  <span className="glyphicon glyphicon-menu-up" />
                )}
                {subMenu === 'pests' && this.renderSubMenuMobile()}
              </div>

              <HeaderLink onClick={this.clearMenu} to="/blog">
                <div className="navbar__link">
                  <span>BLOG</span>
                  <span className="glyphicon glyphicon-menu-down invisible-on-mobile" />
                </div>
              </HeaderLink>

              <HeaderLink onClick={this.clearMenu} to="/contact">
                <div className="navbar__link">
                  <span>CONTACT</span>
                  <span className="glyphicon glyphicon-menu-down invisible-on-mobile" />
                </div>
              </HeaderLink>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
