import React from 'react';
import { Link } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import {
  Element,
  animateScroll as scroll,
  scroller,
} from 'react-scroll';

import NavBar from 'components/NavBar';

import './PestPage.scss';

export default class PestPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.scrollListener = null;

    this.handleScroll = this.handleScroll.bind(this);

    this.scrollToTopOfPage = this.scrollToTopOfPage.bind(this);

    this.scrollToAnts = this.scrollToAnts.bind(this);
    this.scrollToBees = this.scrollToBees.bind(this);
    this.scrollToCockroaches = this.scrollToCockroaches.bind(this);
    this.scrollToCrickets = this.scrollToCrickets.bind(this);
    this.scrollToRodents = this.scrollToRodents.bind(this);
    this.scrollToScorpions = this.scrollToScorpions.bind(this);
    this.scrollToSpiders = this.scrollToSpiders.bind(this);
    this.scrollToTermites = this.scrollToTermites.bind(this);
  }

  handleScroll() {
    this.setState({ scroll: window.scrollY });
  }

  componentDidMount() {
    const el = document.querySelector('.pest-page__body-left');
    this.setState({
      top: el.offsetTop - 70,
      height: el.offsetHeight,
    });

    window.addEventListener('scroll', this.handleScroll, false);

    const path = window.location.hash;

    switch (path) {
      case '#Bees':
        this.scrollToBees();
        break;
      case '#Cockroaches':
        this.scrollToCockroaches();
        break;
      case '#Crickets':
        this.scrollToCrickets();
        break;
      case '#Rodents':
        this.scrollToRodents();
        break;
      case '#Scorpions':
        this.scrollToScorpions();
        break;
      case '#Spiders':
        this.scrollToSpiders();
        break;
      case '#Termites':
        this.scrollToTermites();
        break;
      default:
        this.scrollToTopOfPage();
        break;
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.hash !== prevProps.hash) {
      if (this.props.hash) {
        const hash = this.props.hash.split('#')[1];
        switch (hash) {
          case 'Bees':
            this.scrollToBees();
            break;
          case 'Cockroaches':
            this.scrollToCockroaches();
            break;
          case 'Crickets':
            this.scrollToCrickets();
            break;
          case 'Rodents':
            this.scrollToRodents();
            break;
          case 'Scorpions':
            this.scrollToScorpions();
            break;
          case 'Spiders':
            this.scrollToSpiders();
            break;
          case 'Termites': 
            this.scrollToTermites();
            break;
          default:
            scroller.scrollTo('ants', {
              duration: 500,
              smooth: false,
              offset: -70,
            });
            this.setState({
              ants: true,
              bees: false,
              cockroaches: false,
              crickets: false,
              rodents: false,
              scorpions: false,
              spiders: false,
              termites: false,
            });
            break;
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, false);
  }

  scrollToTopOfPage() {
    scroll.scrollToTop({duration:0});
    this.setState({
      ants: true,
      bees: false,
      cockroaches: false,
      crickets: false,
      rodents: false,
      scorpions: false,
      spiders: false,
      termites: false,
    });
  }

  scrollToAnts() {
    window.location.hash = '#Ants';
    scroller.scrollTo('ants', {
      duration: 500,
      smooth: false,
      offset: -70,
    });

    this.setState({
      ants: true,
      bees: false,
      cockroaches: false,
      crickets: false,
      rodents: false,
      scorpions: false,
      spiders: false,
      termites: false,
    });
  }

  scrollToBees() {
    window.location.hash = '#Bees';
    scroller.scrollTo('bees', {
      duration: 500,
      smooth: false,
      offset: -70,
    });

    this.setState({
      ants: false,
      bees: true,
      cockroaches: false,
      crickets: false,
      rodents: false,
      scorpions: false,
      spiders: false,
      termites: false,
    });
  }

  scrollToCockroaches() {
    window.location.hash = '#Cockroaches';
    scroller.scrollTo('cockroaches', {
      duration: 500,
      smooth: false,
      offset: -70,
    });

    this.setState({
      ants: false,
      bees: false,
      cockroaches: true,
      crickets: false,
      rodents: false,
      scorpions: false,
      spiders: false,
      termites: false,
    });
  }

  scrollToCrickets() {
    window.location.hash = '#Crickets';
    scroller.scrollTo('crickets', {
      duration: 500,
      smooth: false,
      offset: -70,
    });

    this.setState({
      ants: false,
      bees: false,
      cockroaches: false,
      crickets: true,
      rodents: false,
      scorpions: false,
      spiders: false,
      termites: false,
    });
  }

  scrollToRodents() {
    window.location.hash = '#Rodents';
    scroller.scrollTo('rodents', {
      duration: 500,
      smooth: false,
      offset: -70,
    });

    this.setState({
      ants: false,
      bees: false,
      cockroaches: false,
      crickets: false,
      rodents: true,
      scorpions: false,
      spiders: false,
      termites: false,
    });
  }

  scrollToScorpions() {
    window.location.hash = '#Scorpions';
    scroller.scrollTo('scorpions', {
      duration: 500,
      smooth: false,
      offset: -70,
    });

    this.setState({
      ants: false,
      bees: false,
      cockroaches: false,
      crickets: false,
      rodents: false,
      scorpions: true,
      spiders: false,
      termites: false,
    });
  }

  scrollToSpiders() {
    window.location.hash = '#Spiders';
    scroller.scrollTo('spiders', {
      duration: 500,
      smooth: false,
      offset: -70,
    });

    this.setState({
      ants: false,
      bees: false,
      cockroaches: false,
      crickets: false,
      rodents: false,
      scorpions: false,
      spiders: true,
      termites: false,
    });
  }

  scrollToTermites() {
    window.location.hash = '#Termites';
    scroller.scrollTo('termites', {
      duration: 500,
      smooth: false,
      offset: -70,
    });

    this.setState({
      ants: false,
      bees: false,
      cockroaches: false,
      crickets: false,
      rodents: false,
      scorpions: false,
      spiders: false,
      termites: true,
    });
  }

  render() {
    const { scroll, top } = this.state;

    let menuClass = 'pest-page__body-menu';
    menuClass += scroll > top ? ' pest-page__body-menu-fixed' : '';

    let antsClass = 'pest-page__body-menu-link';
    antsClass += this.state.ants ? ' pest-page__body-menu-link-active' : '';

    let beesClass = 'pest-page__body-menu-link';
    beesClass += this.state.bees ? ' pest-page__body-menu-link-active' : '';

    let cockroachesClass = 'pest-page__body-menu-link';
    cockroachesClass += this.state.cockroaches
      ? ' pest-page__body-menu-link-active'
      : '';

    let cricketsClass = 'pest-page__body-menu-link';
    cricketsClass += this.state.crickets
      ? ' pest-page__body-menu-link-active'
      : '';

    let rodentsClass = 'pest-page__body-menu-link';
    rodentsClass += this.state.rodents
      ? ' pest-page__body-menu-link-active'
      : '';

    let scorpionsClass = 'pest-page__body-menu-link';
    scorpionsClass += this.state.scorpions
      ? ' pest-page__body-menu-link-active'
      : '';

    let spidersClass = 'pest-page__body-menu-link';
    spidersClass += this.state.spiders
      ? ' pest-page__body-menu-link-active'
      : '';

    let termitesClass = 'pest-page__body-menu-link';
    termitesClass += this.state.termites
      ? ' pest-page__body-menu-link-active'
      : '';

    return (
      <div className="pest-page">
        <NavBar nav={['Home', 'Pests']} color="red" />

        <div className="pest-page__body">
          <div className="pest-page__body-left">
            <div className={menuClass}>
              <div className={antsClass} onClick={this.scrollToAnts}>
                Ants
              </div>
              <div className={beesClass} onClick={this.scrollToBees}>
                Bees
              </div>
              <div
                className={cockroachesClass}
                onClick={this.scrollToCockroaches}
              >
                Cockroaches
              </div>
              <div className={cricketsClass} onClick={this.scrollToCrickets}>
                Crickets
              </div>
              <div className={rodentsClass} onClick={this.scrollToRodents}>
                Rodents
              </div>
              <div className={scorpionsClass} onClick={this.scrollToScorpions}>
                Scorpions
              </div>
              <div className={spidersClass} onClick={this.scrollToSpiders}>
                Spiders
              </div>
              <div className={termitesClass} onClick={this.scrollToTermites}>
                Termites
              </div>
              <div
                className="pest-page__body-menu-backtop"
                onClick={this.scrollToTopOfPage}
              >
                &#8593; Back to top
              </div>

              <div className="pest-page__body-menu-special-offers">
                Special Offers
              </div>

              <div className="pest-page__body-menu-discount">
                <div className="pest-page__body-menu-discount-header">
                  10% off whole year
                </div>
                <div className="pest-page__body-menu-discount-content">
                  Pay 90% of the whole year upfront for a full year of
                  treatment.
                </div>
                <div className="pest-page__body-menu-discount-redeem">
                  <Link to='/contact#SpecialOffers'>
                    <div className="emerald-button pest-page__body-menu-discount-redeem-btn">
                      <div className="emerald-button-content">
                        <span>Redeem</span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div className="pest-page__body-menu-discount-more">
                <Link to='/contact#SpecialOffers' className="pest-page__body-menu-discount-more">See More Offers!</Link>
              </div>
            </div>
          </div>

          <div className="pest-page__body-right">

            <div className='pest-page__body-mobile-index'>
              <div onClick={this.scrollToAnts}>Ants</div>
              <div onClick={this.scrollToBees}>Bees</div>
              <div onClick={this.scrollToCockroaches}>Cockroaches</div>
              <div onClick={this.scrollToRodents}>Rodents</div>
              <div onClick={this.scrollToScorpions}>Scorpions</div>
              <div onClick={this.scrollToSpiders}>Spiders</div>
              <div onClick={this.scrollToTermites}>Termites</div>
            </div>

            <Element name="ants">
              <div className="pest-page__body-bugs">
                <h1>Ants</h1>
                <div className="pest-page__body-bugs-content">
                  <div className="row">
                    
                    <div className="col-md-6">
                      <div className="pest-page__body-bugs-content-picture pest-page__body-bugs-content-picture-ant">
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="pest-page__body-bugs-content-description">
                        <div className="pest-page__body-bugs-content-info-bold">
                          Scientific Name:
                        </div>
                        <div className="pest-page__body-bugs-content-info">
                          Formicidae
                        </div>

                        <div className="pest-page__body-bugs-content-info-bold">
                          Types:
                        </div>
                        <div className="pest-page__body-bugs-content-info">
                          Carpenter, field, pavement, harvester, velvety tree
                        </div>

                        <div className="pest-page__body-bugs-content-info-bold">
                          How to keep them away:
                        </div>
                        <div className="pest-page__body-bugs-content-info">
                          Sanitation, Eliminate food, grease, and crumbs
                        </div>

                        <div className="pest-page__body-bugs-content-info-bold">
                          Where are they found:
                        </div>
                        <div className="pest-page__body-bugs-content-info">
                          Kitchen, bathrooms, plumbing fixtures, trees, grass,
                          fields, lawn, pavement, mulch
                        </div>

                        <div className="pest-page__body-bugs-content-info-bold">
                          <a href="https://www.shutterstock.com/collections/178360568?verification_code=8c41c466&utm_source=collections&utm_medium=copylink&utm_campaign=lightbox" rel="noopener noreferrer" target="_blank">More pictures</a>
                        </div>

                      </div>
                    </div>
                  </div>
                  Have you ever seen multiple ant hills within inches of each
                  other and wondered why that happens? Ants are smart little
                  creatures, and most people think they can treat them with at
                  home products. However what they don't know is that in order
                  to kill off the colony and prevent them from migrating to a
                  new location, you MUST kill the queen. The only way to reach
                  the queen in time before they are able to build a new home is
                  to use professional grade product. If you do not spray the
                  correct product to treat ants, they will split and form two
                  colonies only a few feet away from the original nest, causing
                  the infestation the grow. This process is called “budding” and
                  is the reason most ant infestations get out of control fast
                  and are why owner have a difficult time locating where the
                  infestation is coming from.
                </div>
              </div>
            </Element>

            <Element name="bees">
              <div className="pest-page__body-bugs">
                <h1>Bees</h1>
                <div className="pest-page__body-bugs-content pest-page__body-bugs-bee">
                  <div className="pest-page__body-bugs-content">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="pest-page__body-bugs-content-picture pest-page__body-bugs-content-picture-bee">
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="pest-page__body-bugs-content-description">
                          <div className="pest-page__body-bugs-content-info-bold">
                            Scientific Name:
                          </div>
                          <div className="pest-page__body-bugs-content-info">
                            Anthophia
                          </div>

                          <div className="pest-page__body-bugs-content-info-bold">
                            Types:
                          </div>
                          <div className="pest-page__body-bugs-content-info">
                            Honey bee, killer bee
                          </div>

                          <div className="pest-page__body-bugs-content-info-bold">
                            How to keep them away:
                          </div>
                          <div className="pest-page__body-bugs-content-info">
                            Seal holes, look for swarming
                          </div>

                          <div className="pest-page__body-bugs-content-info-bold">
                            Where are they found:
                          </div>
                          <div className="pest-page__body-bugs-content-info">
                            Trees, wall openings, meter boxes, rock holes
                          </div>

                          <div className="pest-page__body-bugs-content-info-bold">
                            <a href="https://www.shutterstock.com/collections/178360598?verification_code=231ec992&utm_source=collections&utm_medium=copylink&utm_campaign=lightbox" rel="noopener noreferrer" target="_blank">More pictures</a>
                          </div>

                        </div>
                      </div>
                    </div>
                    There are two types of bees: European Honey bees and
                    Africanized Bees. While they look identical, their behavior is
                    extremely different. The temperament of a traditional European
                    Honey Bee is very mild and generally non aggressive. However
                    Africanized bees, also known as “killer bees” become extremely
                    aggressive, even when unprovoked. What a lot of people don't
                    know is that a regular honey bee hive can become africanized.
                    While traditional honey bees build their forever homes in
                    protected spaces such as cracks in a tree or inside of a water
                    meter box, Africanized Bees are constantly swarming and
                    looking for new homes, causing them to spread.
                  </div>
                </div>
              </div>
            </Element>

            <Element name="cockroaches">
              <div className="pest-page__body-bugs">
                <h1>Cockroaches</h1>
                <div className="pest-page__body-bugs-content pest-page__body-bugs-cockroach">
                  <div className="pest-page__body-bugs-content">
                    <div className="row">
                      
                      <div className="col-md-6">
                        <div className="pest-page__body-bugs-content-picture pest-page__body-bugs-content-picture-cockroach">
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="pest-page__body-bugs-content-description">
                          <div className="pest-page__body-bugs-content-info-bold">
                            Scientific Name:
                          </div>
                          <div className="pest-page__body-bugs-content-info">
                            Blatta
                          </div>

                          <div className="pest-page__body-bugs-content-info-bold">
                            Types:
                          </div>
                          <div className="pest-page__body-bugs-content-info">
                            German, American, Oriental, brown band
                          </div>

                          <div className="pest-page__body-bugs-content-info-bold">
                            How to keep them away:
                          </div>
                          <div className="pest-page__body-bugs-content-info">
                           Santitation, remove debris, cleanliness, seal entry points
                          </div>

                          <div className="pest-page__body-bugs-content-info-bold">
                            Where are they found:
                          </div>
                          <div className="pest-page__body-bugs-content-info">
                            Water box, bathrooms, kitchen, rocks, trash, debris
                          </div>

                          <div className="pest-page__body-bugs-content-info-bold">
                            <a href="https://www.shutterstock.com/collections/178360613?verification_code=de45c4f0&utm_source=collections&utm_medium=copylink&utm_campaign=lightbox" rel="noopener noreferrer" target="_blank">More pictures</a>
                          </div>

                        </div>
                      </div>
                    </div>
                    There are four common types of cockroaches in Utah: German Cockroach, American Cockroach, 
                    Oriental Cockroach, and the Brownband Cockroach. Cockroaches can be one of the hardest pests to 
                    eradicate because of how often they breed and how easily they can hide. Cockroaches like to live in 
                    warm, damp areas where they have easy access to food. The best way to treat roaches is by using professional 
                    bait. The bait we use works because instead of eating crumbs, grease, and other food they find, they eat the 
                    bait and then transport it to the other roaches in the area.
                  </div>
                </div>
              </div>
            </Element>

            <Element name="crickets">
              <div className="pest-page__body-bugs">
                <h1>Crickets</h1>
                <div className="pest-page__body-bugs-content pest-page__body-bugs-cricket">
                  <div className="pest-page__body-bugs-content">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="pest-page__body-bugs-content-picture pest-page__body-bugs-content-picture-cricket">
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="pest-page__body-bugs-content-description">
                          <div className="pest-page__body-bugs-content-info-bold">
                            Scientific Name:
                          </div>
                          <div className="pest-page__body-bugs-content-info">
                            Gryllidae
                          </div>

                          <div className="pest-page__body-bugs-content-info-bold">
                            Types:
                          </div>
                          <div className="pest-page__body-bugs-content-info">
                            Field, house, Mormon
                          </div>

                          <div className="pest-page__body-bugs-content-info-bold">
                            How to keep them away:
                          </div>
                          <div className="pest-page__body-bugs-content-info">
                            Habitat elimination and debris removal
                          </div>

                          <div className="pest-page__body-bugs-content-info-bold">
                            Where are they found:
                          </div>
                          <div className="pest-page__body-bugs-content-info">
                            Home foundation, debris, tall grass, mulch
                          </div>

                          <div className="pest-page__body-bugs-content-info-bold">
                            <a href="https://www.shutterstock.com/collections/178360619?verification_code=0fcd333d&utm_source=collections&utm_medium=copylink&utm_campaign=lightbox" rel="noopener noreferrer" target="_blank">More pictures</a>
                          </div>

                        </div>
                      </div>
                    </div>
                    If you have ever been to a location that has crickets, you have probably realized by the sound that there 
                    aren't just one or two - but hundreds. This is beacause crickets generally live outdoors and can tend to be 
                    more attracted to heavily lift areas, flocking to builds and homes by the dozens. They tend to be found in wooded 
                    areas or areas with tall weeds and debris. The best way to keep crickets out is to prevent overgrowth of weeds or 
                    debris around your home.
                  </div>
                </div>
              </div>
            </Element>

            <Element name="rodents">
              <div className="pest-page__body-bugs">
                <h1>Rodents</h1>
                <div className="pest-page__body-bugs-content pest-page__body-bugs-rodent">
                  <div className="pest-page__body-bugs-content">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="pest-page__body-bugs-content-picture pest-page__body-bugs-content-picture-rodent">
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="pest-page__body-bugs-content-description">
                          <div className="pest-page__body-bugs-content-info-bold">
                            Scientific Name:
                          </div>
                          <div className="pest-page__body-bugs-content-info">
                            Rodentia
                          </div>

                          <div className="pest-page__body-bugs-content-info-bold">
                            Types:
                          </div>
                          <div className="pest-page__body-bugs-content-info">
                            Norway rat, house, deer
                          </div>

                          <div className="pest-page__body-bugs-content-info-bold">
                            How to keep them away:
                          </div>
                          <div className="pest-page__body-bugs-content-info">
                            Clean up, sanitation, eliminate shelter, food, and water
                          </div>

                          <div className="pest-page__body-bugs-content-info-bold">
                            Where are they found:
                          </div>
                          <div className="pest-page__body-bugs-content-info">
                            Attics, A/C units, sheds, homes, wood piles 
                          </div>

                          <div className="pest-page__body-bugs-content-info-bold">
                            <a href="https://www.shutterstock.com/collections/178360649?verification_code=d01c554d&utm_source=collections&utm_medium=copylink&utm_campaign=lightbox" rel="noopener noreferrer" target="_blank">More pictures</a>
                          </div>
                        
                        </div>
                      </div>
                    </div>
                    The most common rodent in Utah is the general House Mouse. Rodents are always worse during 
                    the winter months because they week to make their homes in places that have warmth and easy 
                    access to food, which makes your house the perfect place. Rodents, especially mice, can fit through 
                    extremely small cracks and holes, so it is important to make sure all entry points of your home are properly 
                    sealed and that there are no openings for them to enter. Bait boxes can be extremely effective if you are 
                    experiencing rodent issues in or around your home.
                  </div>
                </div>
              </div>
            </Element>

            <Element name="scorpions">
              <div className="pest-page__body-bugs">
                <h1>Scorpions</h1>
                <div className="pest-page__body-bugs-intro">
                  The Giant Desert Hairy Scorpions as well as Bark Scorpions are
                  both natives to Southern Utah. They are easy to distinguish
                  between because of their size.
                </div>
                <div className="pest-page__body-bugs-content pest-page__body-bugs-desert-scorpions">
                  <div className="pest-page__body-bugs-content">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="pest-page__body-bugs-content-picture pest-page__body-bugs-content-picture-desertscorpion">
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="pest-page__body-bugs-content-description">
                          <div className="pest-page__body-bugs-content-info-bold">
                            Scientific Name:
                          </div>
                          <div className="pest-page__body-bugs-content-info">
                            Scorpiones
                          </div>

                          <div className="pest-page__body-bugs-content-info-bold">
                            Type:
                          </div>
                          <div className="pest-page__body-bugs-content-info">
                            Desert hairy 
                          </div>

                          <div className="pest-page__body-bugs-content-info-bold">
                            How to keep them away:
                          </div>
                          <div className="pest-page__body-bugs-content-info">
                            Remove debris, seal entrances, black light treatment
                          </div>

                          <div className="pest-page__body-bugs-content-info-bold">
                            Where are they found:
                          </div>
                          <div className="pest-page__body-bugs-content-info">
                            Rocks, debris, block walls, palm trees, lumber, pools
                          </div>

                           <div className="pest-page__body-bugs-content-info-bold">
                            <a href="https://www.shutterstock.com/collections/178360706?verification_code=3e239138&utm_source=collections&utm_medium=copylink&utm_campaign=lightbox" rel="noopener noreferrer" target="_blank">More pictures</a>
                          </div>

                        </div>
                      </div>
                    </div>
                    The Desert Hairy Scorpion is the largest scorpion in all of
                    North America - and they have made their home right here in
                    Southern Utah. These predators are large, averaging between
                    5-8 inches in length. It takes professional grade product to
                    kill these large predators BEFORE they enter your home. Our
                    product is designed to kill off large predator pests like
                    these fast.
                  </div>
                </div>
                <br />
                <br />
                <div className="pest-page__body-bugs-content pest-page__body-bugs-arizona-scorpions">
                  <div className="pest-page__body-bugs-content">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="pest-page__body-bugs-content-picture pest-page__body-bugs-content-picture-barkscorpion">
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="pest-page__body-bugs-content-description">
                          <div className="pest-page__body-bugs-content-info-bold">
                            Scientific Name:
                          </div>
                          <div className="pest-page__body-bugs-content-info">
                            Scorpiones
                          </div>

                          <div className="pest-page__body-bugs-content-info-bold">
                            Type:
                          </div>
                          <div className="pest-page__body-bugs-content-info">
                            Bark
                          </div>

                          <div className="pest-page__body-bugs-content-info-bold">
                            How to keep them away:
                          </div>
                          <div className="pest-page__body-bugs-content-info">
                            Remove debris, seal entrances, black light treatment
                          </div>

                          <div className="pest-page__body-bugs-content-info-bold">
                            Where are they found:
                          </div>
                          <div className="pest-page__body-bugs-content-info">
                            Rocks, debris, block walls, palm trees, lumber, pools
                          </div>

                          <div className="pest-page__body-bugs-content-info-bold">
                            <a href="https://www.shutterstock.com/collections/178360706?verification_code=3e239138&utm_source=collections&utm_medium=copylink&utm_campaign=lightbox" rel="noopener noreferrer" target="_blank">More pictures</a>
                          </div>

                        </div>
                      </div>
                    </div>
                    The Bark Scorpion, which is the most venomous scorpion in
                    North America, is much smaller in size - growing only to be
                    2-3 inches in length as adults. A lot of people think that
                    these small scorpions are simply baby scorpions, and that they
                    are so poisonous because they can’t control their venom
                    release, however this is false. These tiny scorpions are in
                    fact not babies, but an entirely different breed. They have
                    flat, thin bodies, making it easy for them to fit through even
                    the smallest cracks and under doors to get into your home.
                    Because of their ability to climb, they can even access your
                    home from second and third stories and like to crawl inside
                    small, moist spaces, like shoes. A sting from one of these
                    little predators can be deadly in children and the elderly,
                    causing sever pain, muscle twitches, hallucinations and
                    seizures. If you believes you have been stung by one, seek
                    medical attention.
                  </div>
                </div>
              </div>
            </Element>

            <Element name="spiders">
              <div className="pest-page__body-bugs">
                <h1>Spiders</h1>
                <div className="pest-page__body-bugs-content pest-page__body-bugs-spiders">
                  <div className="pest-page__body-bugs-content">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="pest-page__body-bugs-content-picture pest-page__body-bugs-content-picture-spider">
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="pest-page__body-bugs-content-description">
                          <div className="pest-page__body-bugs-content-info-bold">
                            Scientific Name:
                          </div>
                          <div className="pest-page__body-bugs-content-info">
                            Araneae
                          </div>

                          <div className="pest-page__body-bugs-content-info-bold">
                            Type:
                          </div>
                          <div className="pest-page__body-bugs-content-info">
                            Black widow, hobo, wolf, funnel, jumping, orb weaver, cobweb
                          </div>

                          <div className="pest-page__body-bugs-content-info-bold">
                            How to keep them away:
                          </div>
                          <div className="pest-page__body-bugs-content-info">
                            Remove webs, seal cracks, seal entry points, habitat elimination
                          </div>

                          <div className="pest-page__body-bugs-content-info-bold">
                            Where are they found:
                          </div>
                          <div className="pest-page__body-bugs-content-info">
                            Eves, window seals, home foundation, plants, water boxes
                          </div>

                          <div className="pest-page__body-bugs-content-info-bold">
                            <a href="https://www.shutterstock.com/collections/178360691?verification_code=2e248b38&utm_source=collections&utm_medium=copylink&utm_campaign=lightbox" rel="noopener noreferrer" target="_blank">More pictures</a>
                          </div>

                        </div>
                      </div>
                    </div>
                    Black widows love small, dark places to build their homes, and
                    little closets with a lot of clutter makes the perfect spot
                    for them. They will find little cracks in block walls, the
                    underneath of your patio furniture, and near wood piles to
                    build their webs. The Black Widow makes a very large, distinct
                    web leading to its home - tall and uneven with no pattern and
                    usually close to the ground. Even if knocked down, the Black
                    Widow will most likely return and build a new web quickly
                    unless the area is treated with the proper product to prevent
                    them from returning.
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </Element>


            <Element name="termites">
              <div className="pest-page__body-bugs">
                <h1>Termites</h1>
                <div className="pest-page__body-bugs-content pest-page__body-bugs-termites">
                  <div className="pest-page__body-bugs-content">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="pest-page__body-bugs-content-picture pest-page__body-bugs-content-picture-termite">
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="pest-page__body-bugs-content-description">
                          <div className="pest-page__body-bugs-content-info-bold">
                            Scientific Name:
                          </div>
                          <div className="pest-page__body-bugs-content-info">
                            Isoptera
                          </div>

                          <div className="pest-page__body-bugs-content-info-bold">
                            Type:
                          </div>
                          <div className="pest-page__body-bugs-content-info">
                            Dry-wood, subterranean 
                          </div>

                          <div className="pest-page__body-bugs-content-info-bold">
                            How to keep them away:
                          </div>
                          <div className="pest-page__body-bugs-content-info">
                            Inspect wood areas throughout the home or have a home inspection a couple times a year
                          </div>

                          <div className="pest-page__body-bugs-content-info-bold">
                            Where are they found:
                          </div>
                          <div className="pest-page__body-bugs-content-info">
                            Wooden constructions in basements and crawl spaces, wood sills, joists, 
                            support posts, basement window frames, and wood underporches. Scrap wood on 
                            the ground or a woodpile next to the house.
                          </div>

                           <div className="pest-page__body-bugs-content-info-bold">
                            <a href="https://www.shutterstock.com/collections/178360775?verification_code=a38d716d&utm_source=collections&utm_medium=copylink&utm_campaign=lightbox" rel="noopener noreferrer" target="_blank">More pictures</a>
                          </div>

                        </div>
                      </div>
                    </div>
                      There are about 150 species of termites throughout the world; however, in Utah there are just two -
                      the subterranean termite species and the drywood species. The primary reproductive caste of termites 
                      include the king and queen. The queen of some species can lay an egg every few seconds over many years. 
                      <br /><br />
                      Subterranean termites are the most destructive insects in the world. They cause over $2 billion in damage 
                      each year in the United States, which is more damage than both fire and windstorm. Subterranean termites make 
                      underground nests and tunnels near wood and can build tunnels more than 30 feet under ground. Finding these 
                      tubes or tunnels is the best way to find where they are feeding.
                      <br /><br />
                      Drywood termites are the second most destructive insect in the world. Drywood termites procreate at a high 
                      rate as well. One of the best ways to find evidence of drywood termites is to find piles of their excrement or grass. 
                      It is very dry and cylindrical in shape. The grass has a seed like shape that is six sided and slightly dimpled on each side. 
                      Often workers will clean out the inside of the wood and push the frass out of kick out holes. Even though damage can 
                      take years to occur from termites, it is important to treat them immediately once found to prevent further damage in the home. 
                      Emerald Pest Control only uses the highest grade products and best equipment when treating termites to give you the best possible 
                      results and because of this, we will guarantee our work up to 5 years! 
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </Element>
          </div>
        </div>
      </div>
    );
  }
}
