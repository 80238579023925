import React from 'react';

import './PestReviewRating.scss';

class PestReviewRating extends React.Component {
  openNewTab() {
    window.open('http://www.pestreviews.org/companies/emeraldpestcontrol-stg','_blank');
  }

  render() {
    return (
      <div className='pestreview-rating' onClick={this.openNewTab}>
        <div className='pestreview-rating__container'>
            
            <div className='pestreview-rating__logo'>
              <div></div>
            </div>
            
            <div className='pestreview-rating__text'>
              <div className='pestreview-rating__description'>PestReview Rating</div>
              <div className='pestreview-rating__rating'>
                  <div className='pestreview-rating__rating-number'>5</div>
                  <div className='pestreview-rating__rating-stars'>
                    <span className="glyphicon glyphicon-star"></span>
                    <span className="glyphicon glyphicon-star"></span>
                    <span className="glyphicon glyphicon-star"></span>
                    <span className="glyphicon glyphicon-star"></span>
                    <span className="glyphicon glyphicon-star"></span>
                  </div>
              </div>
              <div className='pestreview-rating__description-2'>110+ reviews</div>
            </div>
        </div>
      </div>  
    );
  }
}

export default PestReviewRating;
