import React from 'react';

import './FacebookRating.scss';

class FacebookRating extends React.Component {
  openNewTab() {
    window.open('https://www.facebook.com/pg/EmeraldPestControl/reviews/?ref=page_internal','_blank');
  }

  render() {
    return (
      <div className='facebook-rating' onClick={this.openNewTab}>
        <div className='facebook-rating__container'>
            
            <div className='facebook-rating__logo'>
              <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="30" height="30" viewBox="0 0 100 100"><g transform="translate(23,85) scale(0.05,-0.05)"><path fill="#fff" d="M959 1524v-264h-157q-86 0 -116 -36t-30 -108v-189h293l-39 -296h-254v-759h-306v759h-255v296h255v218q0 186 104 288.5t277 102.5q147 0 228 -12z"></path></g></svg>
            </div>
            
            <div className='facebook-rating__text'>
              <div className='facebook-rating__description'>Facebook Rating</div>
              <div className='facebook-rating__rating'>
                  <div className='facebook-rating__rating-number'>5</div>
                  <div className='facebook-rating__rating-stars'>
                    <span className="glyphicon glyphicon-star"></span>
                    <span className="glyphicon glyphicon-star"></span>
                    <span className="glyphicon glyphicon-star"></span>
                    <span className="glyphicon glyphicon-star"></span>
                    <span className="glyphicon glyphicon-star"></span>
                  </div>
              </div>
              <div className='facebook-rating__description-2'>25+ reviews</div>
            </div>
        </div>
      </div>  
    );
  }
}

export default FacebookRating;
