/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 */

import React from 'react';
import * as Scroll from 'react-scroll';
import {
  animateScroll as scroll,
  scroller,
} from 'react-scroll';
import { withRouter } from 'react-router-dom';

import Carousel from 'components/Carousel';
import Bugs from 'components/Bugs';
import Difference from 'components/Difference';
import Review from 'components/Review';
import FiveStep from 'components/FiveStep';
import Locations from 'components/Locations';
import GoodHands from 'components/GoodHands';

import './HomePage.scss';

class HomePage extends React.PureComponent {

  componentDidMount() {
    this.scrollToTheTop(false, 0);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.key !== prevProps.location.key) {
      this.scrollToTheTop(true, 1000);
    }
  }

  scrollToTheTop(smooth, duration) {
    scroll.scrollToTop({
      smooth: smooth,
      duration: duration
    });
  }

  render() {
    return (
      <div>
        <div className="home-page__carousel">
          <Carousel />
        </div>
        <div className="home-page__bugs">
          <Bugs />
        </div>
        <div className="home-page__difference">
          <Difference />
        </div>
        <div className="home-page__review">
          <Review />
        </div>
        <div className="home-page__five-step">
          <FiveStep />
        </div>
        <div className="home-page__locations">
          <Locations />
        </div>
        <div className="home-page__goodhands">
          <GoodHands />
        </div>
      </div>
    );
  }
}

export default withRouter(HomePage);
