import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import './NavBar.scss';

export default class NavBar extends React.PureComponent {
  renderNavHTML() {
    return _.map(this.props.nav, (nav, index) => (
      <div key={`page-nav-bar-${index}`}>
        {nav === 'Home' && (
          <Link to='/'>
            <img
              className="page-nav-bar__img"
              src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTguMS4xLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDI3LjAyIDI3LjAyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyNy4wMiAyNy4wMjsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxMjhweCIgaGVpZ2h0PSIxMjhweCI+CjxnPgoJPHBhdGggZD0iTTMuNjc0LDI0Ljg3NmMwLDAtMC4wMjQsMC42MDQsMC41NjYsMC42MDRjMC43MzQsMCw2LjgxMS0wLjAwOCw2LjgxMS0wLjAwOGwwLjAxLTUuNTgxICAgYzAsMC0wLjA5Ni0wLjkyLDAuNzk3LTAuOTJoMi44MjZjMS4wNTYsMCwwLjk5MSwwLjkyLDAuOTkxLDAuOTJsLTAuMDEyLDUuNTYzYzAsMCw1Ljc2MiwwLDYuNjY3LDAgICBjMC43NDksMCwwLjcxNS0wLjc1MiwwLjcxNS0wLjc1MlYxNC40MTNsLTkuMzk2LTguMzU4bC05Ljk3NSw4LjM1OEMzLjY3NCwxNC40MTMsMy42NzQsMjQuODc2LDMuNjc0LDI0Ljg3NnoiIGZpbGw9IiNGRkZGRkYiLz4KCTxwYXRoIGQ9Ik0wLDEzLjYzNWMwLDAsMC44NDcsMS41NjEsMi42OTQsMGwxMS4wMzgtOS4zMzhsMTAuMzQ5LDkuMjhjMi4xMzgsMS41NDIsMi45MzksMCwyLjkzOSwwICAgTDEzLjczMiwxLjU0TDAsMTMuNjM1eiIgZmlsbD0iI0ZGRkZGRiIvPgoJPHBvbHlnb24gcG9pbnRzPSIyMy44Myw0LjI3NSAyMS4xNjgsNC4yNzUgMjEuMTc5LDcuNTAzIDIzLjgzLDkuNzUyICAiIGZpbGw9IiNGRkZGRkYiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K"
              alt='home-icon'
            />
          </Link>
        )}
        {nav !== 'Home' && nav}
        {index < this.props.nav.length - 1 && (
          <div className="page-nav-bar__line-bracket">
            <img alt='line-icon' src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMS4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDQ3Ny4xNzUgNDc3LjE3NSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDc3LjE3NSA0NzcuMTc1OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjEyOHB4IiBoZWlnaHQ9IjEyOHB4Ij4KPGc+Cgk8cGF0aCBkPSJNMzYwLjczMSwyMjkuMDc1bC0yMjUuMS0yMjUuMWMtNS4zLTUuMy0xMy44LTUuMy0xOS4xLDBzLTUuMywxMy44LDAsMTkuMWwyMTUuNSwyMTUuNWwtMjE1LjUsMjE1LjUgICBjLTUuMyw1LjMtNS4zLDEzLjgsMCwxOS4xYzIuNiwyLjYsNi4xLDQsOS41LDRjMy40LDAsNi45LTEuMyw5LjUtNGwyMjUuMS0yMjUuMUMzNjUuOTMxLDI0Mi44NzUsMzY1LjkzMSwyMzQuMjc1LDM2MC43MzEsMjI5LjA3NXogICAiIGZpbGw9IiNGRkZGRkYiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K" />
          </div>
        )}
      </div>
    ));
  }

  render() {
    const navHTML = this.renderNavHTML();
    
    let className = 'page-nav-bar';
    
    if (!this.props.color) {

    } else if (this.props.color === 'blue') {
      className = 'page-nav-bar page-nav-bar-blue';
    } else if( this.props.color === 'red') {
      className = 'page-nav-bar page-nav-bar-red';
    } else if( this.props.color === 'yellow') {
      className = 'page-nav-bar page-nav-bar-yellow';
    } else if( this.props.color === 'purple') {
      className = 'page-nav-bar page-nav-bar-purple';
    }


    return (
      <div className={className}>
        <div className="page-nav-bar__line">{navHTML}</div>
      </div>
    );
  }
}
