import React from 'react';
import { Link } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import {
  Element,
  animateScroll as scroll,
  scroller,
} from 'react-scroll';

import NavBar from 'components/NavBar';

import './BlogPage.scss';

export default class BlogPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.scrollListener = null;

    this.handleScroll = this.handleScroll.bind(this);

    this.scrollToTopOfPage = this.scrollToTopOfPage.bind(this);

    this.scrollToPost1 = this.scrollToPost1.bind(this);
  }

  handleScroll() {
    this.setState({ scroll: window.scrollY });
  }

  componentDidMount() {
    const el = document.querySelector('.blog-page__body-left');
    this.setState({
      top: el.offsetTop - 70,
      height: el.offsetHeight,
    });

    window.addEventListener('scroll', this.handleScroll, false);

    let path = window.location.hash;

    switch(path) {
      case '#SpringIsHere':
        this.scrollToPost1();
        break;
      default: 
        this.scrollToTopOfPage();
        break;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.hash !== prevProps.hash) {
      if(typeof this.props.hash === "string") {
        let hash = this.props.hash.split('#')[1];
        switch(hash) {
          case 'SpringIsHere':
            this.scrollToPost1();
            break;
          default:
            this.scrollToTopOfPage();
            break;
        }
      }
    }
  }


  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, false);

  }

  scrollToTopOfPage() {
    scroll.scrollToTop({duration: 0});
    this.setState({
      post1: true,
      // whyEmerald: false,
      // reviews: false,
      // faq: false,
    }); 
  }

  scrollToPost1() {
    window.location.hash = '#SpringIsHere';
    scroller.scrollTo('post1', {
      duration: 0,
      smooth: false,
      offset: -70,
    });

    this.setState({
      post1: true,
      // whyEmerald: false,
      // reviews: false,
      // faq: false,
    });
  }

  render() {
    const { scroll, top } = this.state;

    let menuClass = 'blog-page__body-menu';
    menuClass += scroll > top ? ' blog-page__body-menu-fixed' : '';

    let meetOwnerClass = 'blog-page__body-menu-link';
    meetOwnerClass += this.state.post1 ? ' blog-page__body-menu-link-active' : '';

    return (
      <div className="blog-page">
        <NavBar nav={['Home', 'Blog']} color="purple"/>

        <div className="blog-page__body">
          <div className="blog-page__body-left">
            <div className={menuClass}>
              <div className={meetOwnerClass} onClick={this.scrollToPost1}>Spring is here! - <div className='blog-page__body-menu-date'>3/5/19</div></div>
              <div
                className="blog-page__body-menu-backtop"
                onClick={this.scrollToTopOfPage}
              >
                &#8593; Back to top
              </div>

              <div className="blog-page__body-menu-special-offers">
                Special Offers
              </div>

              <div className="blog-page__body-menu-discount">
                <div className="blog-page__body-menu-discount-header">
                  First timers 50% DISCOUNT
                </div>
                <div className="blog-page__body-menu-discount-content">
                  Come join our Emerald family and receive 1/2 off on the first
                  treatment!
                </div>
                <div className="blog-page__body-menu-discount-redeem">
                  <Link to='/contact#SpecialOffers'> 
                    <div className="emerald-button blog-page__body-menu-discount-redeem-btn">
                      <div className="emerald-button-content">
                        <span>Redeem</span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div className="blog-page__body-menu-discount-more">
                <Link to='/contact#SpecialOffers' className='blog-page__body-menu-discount-more'> See More Offers!</Link>
              </div>
            </div>
          </div>

          <div className="blog-page__body-right">
            <Element name="post1">
              <div className="blog-page__body-post1">
                <h1>Spring is here!</h1>
                <h5>March 5, 2019</h5>
                <div className="blog-page__body-post1-content">
                 Spring showers brings May flowers - and lots of unwanted pests! You tend to see a quick increase in bugs 
                 during rainy weather because the cracks and holes that they have been living in for the winter are going 
                 to fill with water. This forces them to find dry places to live until the rain passes - and your warm, cozy 
                 home makes the perfect place for them to build their new nest. This makes spring the perfect time to create 
                 that barrier so that once the bugs come out, their homes are destroyed and your home is PROTECTED. Our product 
                 is time-released so it wont wear off OR become less-effective due to heat or rain.</div>
                 <br />
                 <br />
                 <br />
                 <br />
                 <br />
                 <br />
                 <br />
                 <br />
                 <br />
                 <br />
                 <br />
              </div>
            </Element>

            <div className="blog-page__emptyspace">

            </div>



          </div>
        </div>
      </div>
    );
  }
}
